import "photoswipe/dist/photoswipe.css";
import { Gallery as PhotoswipeGallery, Item } from "react-photoswipe-gallery";
import { memo, useState } from "react";

import closeIc from "../../../image/icon/close_ic.svg";
import plusIcon from "../../../image/icon/plus_ic.svg";
import DeletePicturePopup from "../DeletePicturePopup";

const getImageProperty = (src) => {
	const img = new Image();
	img.src = src;

	return {
		width: img.width,
		height: img.height,
	};
};

const Gallery = (props) => {
	const [deletePicturePopup, setDeletePicturePopup] = useState({
		visible: false,
		name: "",
		location: "",
	});

	//TODO: need to  add style for delete, check logic
	return (
		<>
			<div className={`gallery ${!props.editMode ? "disable" : ""}`}>
				<p className="gallery__headline">
					Gallery
				</p>
				{
					props.data?.gallery && (
						<PhotoswipeGallery>
							<div className="gallery__list">
								{
									props.data?.gallery.map((item, index) => {
										return (
											<Item
												original={item.location}
												thumbnail={item.location}
												width={getImageProperty(item.location).width}
												height={getImageProperty(item.location).height}
												key={index}
											>
												{({ref, open}: { ref: any, open: any }) => (
													<div className="gallery__list__item">
														<img
															ref={ref}
															className="gallery__list__img"
															alt={item.location}
															onClick={open}
															src={item.location}
														/>
														<div
															className="gallery__delete"
															onClick={() => setDeletePicturePopup({
																...deletePicturePopup,
																visible: true,
																location: item.location,
																name: item.originalname,
															})}
														>
															<img src={closeIc} alt="delete ic"/>
														</div>
													</div>
												)}
											</Item>
										);
									})
								}
							</div>
						</PhotoswipeGallery>
					)
				}
				{props.editMode && <button
					className={`gallery__upload ${props.data?.gallery ? "" : "empty"}`}
					onClick={() => props.setDisplayGalleryPopup(true)}
				>
					<img
						src={plusIcon}
						alt="plus icon"
						className="gallery__upload__img"
					/>
					<p
						className="gallery__upload__text"
					>
						Upload files
					</p>
				</button>}
			</div>
			{
				props.data?.gallery && deletePicturePopup.visible && (
					<DeletePicturePopup
						deletePicturePopup={deletePicturePopup}
						allImages={props.data?.gallery}
						setDeletePicturePopup={setDeletePicturePopup}
						setRefetch={props.setRefetch}
					/>
				)
			}
		</>
	);
};

export default memo(Gallery);