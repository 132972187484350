import {memo} from "react";

const tableEmpty = require('../../../../image/table-empty.svg')
const emptySearch = require('../../../../image/search-empty.svg')

const EmptySearch = ({ setSearchValue, searchValue, flag }) => {

  return (

    <div className="empty-search">
      <img src={!flag ? emptySearch : tableEmpty} alt="empty search" className="empty-search__image"/>
      <p className="empty-search__headline">
        {!flag ? 'Ooops! ' : 'Table is empty'}
      </p>
      <p className="empty-search__tagline">
        {!flag ? `We couldn't find any results for your query "${searchValue}"` : 'Don’t have matched profiles for now'}
      </p>
      {
        !flag && (
          <button
            className="empty-search__button button--filled"
            onClick={() => setSearchValue(' ')}
          >
            Reset search
          </button>
        )
      }
    </div>
  )
}

export default memo(EmptySearch)