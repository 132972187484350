import deleteData from '../../../hook/deleteData';
import { getEnv } from "@urecruits/api";
import { memo } from "react";
const deleteIc = require('../../../image/icon/delete_image.svg')

const DeletePopup = ({ setCancelSubscriptionData, cancelSubscriptionData, title, description, setRefetch }) => {
  const { API_RECRUITMENT } = getEnv()

  const onDeleteCardHandler = async () => {
    await deleteData(`${API_RECRUITMENT}/api/subscription/delete-subscription`).then(res => {
      setCancelSubscriptionData({ ...cancelSubscriptionData, popupVisible: false })
      setRefetch(new Date())
    })
  }

  const onClosePopup = () => {
    setCancelSubscriptionData({ ...cancelSubscriptionData, popupVisible: false })
  }

  return (
    // <AuthGuard module='subscription' permission='delete'>
      <div className="popup">
        <div className="popup__step">
          <div className="popup__step__head">
            <p className="popup__step__headline">
              {title}
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              className="popup__step__close"
              onClick={onClosePopup}
            >
              <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <div className="popup__step__body">
            <img src={deleteIc} alt="delete icon" className="popup__step__image" />
            <p className="popup__step__info">
              {description}
            </p>
          </div>
          <div className="popup__step__control end">
            <div
              className="popup__step__button button--filled"
              onClick={onDeleteCardHandler}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    // </AuthGuard> 
  )
}

export default memo(DeletePopup)