import deleteData from '../../../hook/deleteData'
import {memo} from "react";
import {getEnv} from "@urecruits/api";

const deleteImg = require('../../../image/icon/delete_image.svg')

const DeleteUserPopup = ({ setDeleteUserPopup, deleteUserPopup, setMembersRefetch, setDataRefetch }) => {

  const onClosePopup = () => {
    setDeleteUserPopup({
      ...deleteUserPopup,
      visible: false,
      userName: '',
      userId: 0
    })
  }
  const {API_RECRUITMENT}=getEnv()

  const onConfirmDelete = async () => {
    const res = await deleteData(`${API_RECRUITMENT}/api/company/delete-member`, {
      userId: deleteUserPopup.userId,
    })
    if (res.status == 200) {
      onClosePopup()
      setMembersRefetch(new Date())
      setDataRefetch(new Date())
    }
  }

  return (
    <div className="delete-user">
      <div className="delete-user__inner">
        <div className="delete-user__head">
          <p className="delete-user__head__title">
            Delete User
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="delete-user__head__close"
            onClick={onClosePopup}
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="delete-user__body">
          <img src={deleteImg} alt="delete img" className="delete-user__body__image"/>
          <p className="delete-user__body__information">
            Are you sure you want to delete member: <span>{deleteUserPopup.userName}?</span> This action will delete all
            information that connects with this user.
          </p>
          <div className="delete-user__body__buttons">
            <button
              className="delete-user__body__cancel button--empty"
              onClick={onClosePopup}
            >
              Cancel
            </button>
            <button
              className="delete-user__body__confirm button--filled"
              onClick={onConfirmDelete}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(DeleteUserPopup)