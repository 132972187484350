import { memo, useEffect, useState } from 'react'
import PopupPictureUpload from '../component/company/PopupPictureUpload'
import { default as ReactSelect } from 'react-select'
import { selectMobileMenuStyle } from '../styles/selectMobileMenuStyle'
import ManagePasswordTab from '../component/company/ManagePasswordTab'
import CompanyDetail from '../component/company/detail/CompanyDetail'
import PaymentMethodsTab from '../component/company/payment/PaymentMethodsTab'
import AccessManagemantTab from '../component/company/access/AccessManagementTab'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import SubscriptionTab from '../component/company/subscription/SubscriptionTab'
import fetchData from '../hook/fetchData'
import ModuleAccessPopup from '../component/company/ModuleAccessPopup'
import Integrations from '../component/company/integrations/Integrations'
import { getEnv } from "@urecruits/api"
import PopupGalleryUpload from "../component/company/PopupGalleryUpload"
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'


const stripePromise = loadStripe('pk_test_51KToKtBX9fRLZIirWZdzZwegbQWZu0u2NCyblWrJh6DhYqyoFEjmAtTlJF0n2naWlWmZA2wY1BXAhCPHyaTvzVGC00Pzzn7Y4A')

const CompanyProfileScreen = ({ countryList, usState }) => {
  const { checkUserPermission } = useHasPermission()
  const [activeTabName, setActiveTabName] = useState('companyDetails')
  const [displayUploadPopup, setDisplayUploadPopup] = useState(false)
  const [displayGalleryPopup, setDisplayGalleryPopup] = useState(false)
  const [displayAccessPopup, setDisplayAccessPopup] = useState(false)
  const [editStatusForPopup, setEditStatusForPopup] = useState(false)
  const { API_RECRUITMENT } = getEnv()

  const [refetch, setRefetch] = useState('')
  const [data, setData] = useState(null)

  useEffect(() => {
    checkUserPermission('company-owner') && checkUserPermission('company-profile', 'view') && fetchData(`${API_RECRUITMENT}/api/company`, setData).then()
  }, [refetch])
  const menuOption: any = [
    {...(checkUserPermission('company-profile',['view','edit']) ? { value: 'companyDetails', label: 'Company Details' } : {})},
    {...(checkUserPermission('payment-integration',['view','edit','add']) ?  { value: 'paymentsMethods', label: 'Payment methods' } : {})},
    {...(checkUserPermission('subscription',['view','edit','add','delete']) ? { value: 'subscriptions', label: 'Subscriptions' } : {})},
    {...(checkUserPermission('team-members',['view','edit','delete']) ? { value: 'accessManagement', label: 'Access Management' } : {})},
    { value: 'managePassword', label: 'Manage Password' },
    { value: 'integrations', label: 'Integrations' },
  ]

  return (
    <AuthGuard module='company-owner'>
      <Elements stripe={stripePromise}>
        <section className="profile">
          <div
            className={`profile__inner ${activeTabName === 'subscRiptions' ? 'subscriptions' : ''} ${activeTabName === 'accessManagement' ? 'access' : ''}`}>
            <h2 className="profile__headline">
              Company Profile
            </h2>
            <div className="profile__left">
              <ul className="profile__left__menu">
                <AuthGuard module='company-profile' permission={['view', 'edit']}>
                  <li
                    className={`profile__left__item ${activeTabName === 'companyDetails' ? 'active' : ''}`}
                    onClick={() => setActiveTabName('companyDetails')}
                  >
                    Company Details
                  </li>
                </AuthGuard>
                <AuthGuard module='payment-integration' permission={['view', 'edit']}>
                  <li
                    className={`profile__left__item ${activeTabName === 'paymentsMethods' ? 'active' : ''}`}
                    onClick={() => setActiveTabName('paymentsMethods')}
                  >
                    Payment Methods
                  </li>
                </AuthGuard>
                <AuthGuard module='subscription' permission={['view', 'add', 'edit', 'delete']}>
                  <li
                    className={`profile__left__item ${activeTabName === 'subscriptions' ? 'active' : ''}`}
                    onClick={() => setActiveTabName('subscriptions')}
                  >
                    Subscriptions
                  </li>
                </AuthGuard>
                <AuthGuard module='team-members' permission={['edit', 'view', 'delete']}>
                  <li
                    className={`profile__left__item ${activeTabName === 'accessManagement' ? 'active' : ''}`}
                    onClick={() => setActiveTabName('accessManagement')}
                  >
                    Access Management
                  </li>
                </AuthGuard>
                <li
                  className={`profile__left__item ${activeTabName === 'integrations' ? 'active' : ''}`}
                  onClick={() => setActiveTabName('integrations')}
                >
                  Integrations
                </li>
                <li
                  className={`profile__left__item ${activeTabName === 'managePassword' ? 'active' : ''}`}
                  onClick={() => setActiveTabName('managePassword')}
                >
                  Manage Password
                </li>
              </ul>
              <div className="profile__left__mobile-menu">
                <ReactSelect
                  options={menuOption}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={(item: any) => {
                    setActiveTabName(item.value)
                  }}
                  defaultValue={{ label: 'Quick View', value: 0 }}
                  styles={selectMobileMenuStyle}
                  id="mobileMenu"
                  instanceId="mobileMenu"
                />
              </div>
            </div>
            <div
              className={`profile__right ${activeTabName === 'managePassword' ? 'password' : ''}  ${activeTabName === 'subscriptions' ? 'subscriptions' : ''}`}>
              {
                activeTabName === 'companyDetails' && (
                  <CompanyDetail
                    data={data}
                    countryList={countryList}
                    setRefetch={setRefetch}
                    setDisplayUploadPopup={setDisplayUploadPopup}
                    setEditStatusForPopup={setEditStatusForPopup}
                    setDisplayGalleryPopup={setDisplayGalleryPopup}
                  />
                )
              }
              {
                activeTabName === 'paymentsMethods' && (
                  <PaymentMethodsTab
                    usState={usState}
                  />
                )
              }
              {
                activeTabName === 'managePassword' && (
                  <ManagePasswordTab data={data} />
                )
              }
              {
                activeTabName === 'subscriptions' && (
                  <SubscriptionTab />
                )
              }
              {
                activeTabName === 'integrations' && (
                  <Integrations />
                )
              }
              {
                activeTabName === 'accessManagement' && (
                  <AccessManagemantTab setDisplayAccessPopup={setDisplayAccessPopup} />
                )
              }
            </div>
          </div>
        </section>
        {displayUploadPopup && editStatusForPopup && (
          <PopupPictureUpload
            setDisplayUploadPopup={setDisplayUploadPopup}
            data={data}
            setRefetch={setRefetch}
          />
        )}
        {
          displayGalleryPopup && (
            <PopupGalleryUpload
              setDisplayGalleryPopup={setDisplayGalleryPopup}
              setRefetch={setRefetch}
              userData={data}
            />
          )
        }
        {displayAccessPopup && (
          <ModuleAccessPopup
            setDisplayAccessPopup={setDisplayAccessPopup}
          />
        )}
      </Elements>
    </AuthGuard>
  )
}

export default memo(CompanyProfileScreen)