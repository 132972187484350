import { IPermissionData, IPermissions, IUpdatePermission } from '../types/acess-tab/members-table';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialPermissions: IPermissions = {
  dbPermissions: [],
  userId: 0
};

const permissionsReducer = createSlice({
  name: "permissions",
  initialState: initialPermissions,
  reducers: {
    setPermissions(state, action: PayloadAction<IPermissionData[]>) {
      state.dbPermissions = action.payload; 
    },
    updatePermissions(state, action: PayloadAction<IUpdatePermission>) {
      const { name, permissionKey, value,role } = action.payload;
      
      const oldDBpermissions = state.dbPermissions.map((item) => {
        if(item.auth0_id === role) {
          return {...item,permissions:item.permissions.map(i => {
            if(i.name === name) {
              return {
                ...i,
                [permissionKey]: value
              }
            }
            else {
              return i
            }
          })
        }
        } else {
          return item 
        }
      })
      state.dbPermissions = oldDBpermissions
    },
    setUserId(state, action: PayloadAction<number>) {
      state.userId = action.payload; 
    },
  },
});

export const { setPermissions, updatePermissions, setUserId } = permissionsReducer.actions;

export default permissionsReducer.reducer;
