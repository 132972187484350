import { memo, useEffect, useState } from "react";
import { validatePassword } from "../../hook/validatePassword";
import sbsPasswordCheckForErrors from "../../hook/sbsPasswordCheckForErrors";
import { getEnv } from "@urecruits/api";
import patchData from "../../hook/patchData";

const editIc = require("../../image/icon/edit_ic.svg");
const hidePasswordIc = require("../../image/icon/hide_password_ic.svg");
const displayPasswordIc = require("../../image/icon/open_password_ic.svg");

const ManagePasswordTab = ({data}) => {
  const [success, setSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [displayButton, setDisplayButton] = useState(false);

  //display/hide password
  const [displayCurrentPassword, setDisplayCurrentPassword] = useState(false);
  const [displayNewPassword, setDisplayNewPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

  //form state
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  //error state
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  //validate password state
  const [sbsPasswordValidation, setSbsPasswordValidation] = useState(false)
  const [minLength, setMinLength] = useState(false)
  const [oneNumber, setOneNumber] = useState(false)
  const [azSmallSymbol, setAzSmallSymbol] = useState(false)
  const [azBigSymbol, setAzBigSymbol] = useState(false)
  const [specialCharacters, setSpecialCharacters] = useState(false)

  useEffect(() => {
    sbsPasswordCheckForErrors(newPassword, setMinLength, setOneNumber, setAzSmallSymbol, setAzBigSymbol, setSpecialCharacters)
  }, [newPassword])
  const {API_RECRUITMENT}=getEnv()

  const onFormSubmit = async (e) => {
    e.preventDefault()
    validatePassword(newPassword) ? setPasswordError(false) : setPasswordError(true)
    newPassword !== confirmNewPassword ? setConfirmPasswordError(true) : setConfirmPasswordError(false)

    if (validatePassword(newPassword) && newPassword === confirmNewPassword) {

      const data = {
        'currentPassword': currentPassword,
        'newPassword': newPassword,
      }
      //Need to added post
      await patchData(`${API_RECRUITMENT}/api/user/change-password`, data).then((res) => {
        setSuccess(true)
      }).catch(err => {
        console.log(err)
        setCurrentPasswordError(true)
      })

      setTimeout(() => {
        setSuccess(false)
        setCurrentPasswordError(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }

  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)

    //clear field
    setCurrentPassword('')
    setNewPassword('')
    setConfirmNewPassword('')
    //clear error style
    setConfirmPasswordError(false)
    setPasswordError(false)
    setCurrentPasswordError(false)
    setSbsPasswordValidation(false)
    setMinLength(false)
    setOneNumber(false)
    setAzSmallSymbol(false)
    setAzBigSymbol(false)
    setSpecialCharacters(false)
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Manage Password {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        <div className={`profile__form__item long ${currentPasswordError ? "error" : ""} `}>
          <label className="profile__form__label">Your Current Password</label>
          <div className="password-inner">
            <input
              type={displayCurrentPassword ? "text" : "password"}
              className="profile__form__input"
              placeholder={`${editMode ? "Enter your current password" : "Not indicated"}`}
              readOnly={!editMode}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            {
              !displayCurrentPassword ? (
                <img
                  src={hidePasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayCurrentPassword(true)}
                />
              ) : (
                <img
                  src={displayPasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayCurrentPassword(false)}
                />
              )
            }
          </div>
          <p className="error-message">
            Incorrect password
          </p>
        </div>
        <div
          className={`profile__form__item long ${passwordError ? "error" : ""} ${confirmPasswordError ? "error" : ""}`}>
          <div className="profile__form__group">
            <label className="profile__form__label">New password<span> *</span></label>
            <div className="profile__validate">
              <p className={`profile__validate__item ${sbsPasswordValidation ? "focus" : ""} ${minLength ? "active" : ""}`}>8 min,</p>
              <p className={`profile__validate__item ${sbsPasswordValidation ? "focus" : ""} ${oneNumber ? "active" : ""}`}>1 num,</p>
              <p className={`profile__validate__item ${sbsPasswordValidation ? "focus" : ""} ${azSmallSymbol ? "active" : ""}`}>a-z,</p>
              <p className={`profile__validate__item ${sbsPasswordValidation ? "focus" : ""} ${azBigSymbol ? "active" : ""}`}>A-Z,</p>
              <p className={`profile__validate__item ${sbsPasswordValidation ? "focus" : ""} ${specialCharacters ? "active" : ""}`}>special
                characters</p>
            </div>
          </div>
          <div className="password-inner">
            <input
              type={displayNewPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder={`${editMode ? "Enter new password" : "Not indicated"}`}
              className="profile__form__input password"
              value={newPassword}
              readOnly={!editMode}
              onChange={(e) => {
                setNewPassword(e.target.value);
                validatePassword(e.target.value) ? setPasswordError(false) : setPasswordError(true);
                setSbsPasswordValidation(true);
              }}
            />
            {
              !displayNewPassword ? (
                <img
                  src={hidePasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayNewPassword(true)}
                />
              ) : (
                <img
                  src={displayPasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayNewPassword(false)}
                />
              )
            }
          </div>
          <div className="password-inner">
            <input
              type={displayConfirmPassword ? "text" : "password"}
              autoComplete="new-password"
              placeholder={`${editMode ? "Confirm password" : "Not indicated"}`}
              className="profile__form__input confirm conform-password"
              value={confirmNewPassword}
              readOnly={!editMode}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
                newPassword !== e.target.value ? setConfirmPasswordError(true) : setConfirmPasswordError(false);
              }}
            />
            {
              !displayConfirmPassword ? (
                <img
                  src={hidePasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayConfirmPassword(true)}
                />
              ) : (
                <img
                  src={displayPasswordIc}
                  alt="icon" className="password-type"
                  onClick={() => editMode && setDisplayConfirmPassword(false)}
                />
              )
            }
          </div>
          <p className="error-message">
            {
              confirmPasswordError && ("Fields must be match.")
            }
            {
              passwordError && (" Password does not match validation.")
            }
          </p>
        </div>
        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
                <button className="profile__form__submit button--filled" type="submit">Save</button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(ManagePasswordTab)