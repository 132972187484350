import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import PermissionsTableItem from './PermissionsTableItem';
import { memo } from "react";

const PermissionsTable = ({ data,role }) => {

  return (
    <AuthGuard module='team-members' permission={['edit','view']}>
    <div className="permissions-table">
      <div className="permissions-table__head">
        <div className="permissions-table__head__left">
          <p className="permissions-table__head__title">Module Access</p>
        </div>
        <div className="permissions-table__head__right">
          <p className="permissions-table__head__title">Permissions</p>
        </div>
      </div>
      <div className="permissions-table__list">
        {
          data?.map((item, index) => {
            return (
              <PermissionsTableItem item={item} key={index} role={role} />
            )
          })
        }
      </div>
    </div>
    </AuthGuard>
  )
}

export default memo(PermissionsTable)