import DatePicker from 'react-datepicker'
import {memo, useEffect, useState} from 'react'
import deleteData from "../../../hook/deleteData";
import {getEnv} from "@urecruits/api";
import patchData from '../../../hook/patchData';
import { Box, Chip } from "@mui/material";

const deleteIc = require('../../../image/icon/delete_ic.svg')

const ProjectsDetailsTabItem =
  ({
     editMode,
     currentItem,
     setDataDetails,
     dataDetails,
     setValidateStatus,
     validateStatus,
     displayButton,
     addedMoreEducationButtonRef,
     submitButtonRef,
     setRefetch,
     index,
     setDisplayButton,
     setData
   }) => {
    const [projectNameError, setProjectNameError] = useState(false)
    const [periodDateStartError, setPeriodDateStartError] = useState(false)
    const [periodDateEndError, setPeriodDateEndError] = useState(false)
    const [awardDateEndError, setAwardDateEndError] = useState(false)

    const fieldsValidate = () => {
      let error = 0
      dataDetails.map((object) => {
        !object.projectName || object.projectName.length <= 0 ? setProjectNameError(true) : setProjectNameError(false)
        !object.dateStart ? setPeriodDateStartError(true) : setPeriodDateStartError(false)
        !object.dateEnd ? setPeriodDateEndError(true) : setPeriodDateEndError(false)
        !object.awardDate ? setAwardDateEndError(true) : setAwardDateEndError(false)

        if (!object.projectName || object.projectName.length <= 0 || !object.dateStart || !object.dateEnd || !object.awardDate) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }
    const {API_RECRUITMENT}=getEnv()
    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreEducationButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, dataDetails, currentItem, editMode])

    const onRemoveItemHandler = async (e) => {
      e.preventDefault
      if (editMode) {
        if (currentItem?.isResumeParsed) {
          setData(oldData => {
            let projects = [];
            projects = dataDetails?.filter(i => i.id !== currentItem.id)
            oldData.projects = projects;
            return { ...oldData }
          });
        } else {
          const res = await deleteData(`${API_RECRUITMENT}/api/candidate/project`, currentItem);
          if (res.status == 200) {
            setData(oldData => {
              const _oldData = JSON.parse(JSON.stringify(oldData));
              let projects = dataDetails;
              projects = projects.filter(item => item.id !== currentItem.id);
              return { ..._oldData, projects }
            })
          }
        }
      }
    }
    
    return (
      <>
        <div className="profile__form__part">
          <div className={`flex`}>
            <Box>
              {currentItem?.isResumeParsed && <Chip variant='outlined' color='error' label="Unsaved" />}
            </Box>
            <div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
              <div className="profile__form__delete--inner" onClick={(e) => onRemoveItemHandler(e)}>
                <span className="profile__form__delete--text">Delete</span>
                <img src={deleteIc} alt="delete icon" className="profile__form__delete--icon" />
              </div>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item long ${projectNameError ? 'error' : ''}`}>
              <label className="profile__form__label">Project Name<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter project name' : 'Not indicated'}`}
                readOnly={!editMode}
                value={currentItem.projectName?currentItem.projectName:''}
                onChange={(e) => {
                  setDataDetails([...dataDetails].map(object => {
                    if (object.id === currentItem.id) {
                      return {
                        ...object,
                        projectName: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setProjectNameError(true) : setProjectNameError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item short after-line ${periodDateStartError ? 'error' : ''}`}>
              <label className="profile__form__label">Period Date<span> *</span></label>
              <div className="profile__form__date">
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  selected={currentItem.dateStart ? new Date(currentItem.dateStart) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setDataDetails([...dataDetails].map(object => {
                      if (object.id === currentItem.id) {
                        return {
                          ...object,
                          dateStart: date,
                        }
                      } else return object
                    }))
                    !date ? setPeriodDateStartError(true) : setPeriodDateStartError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  readOnly={!editMode}
                  placeholderText="Start of period"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item short ${periodDateEndError ? 'error' : ''}`}>
              {/*<label className="profile__form__label">Period Date<span> *</span></label>*/}
              <div className={`profile__form__date ${!currentItem.dateStart && 'profile__form__date--readonly'}`}>
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  selected={currentItem.dateEnd ? new Date(currentItem.dateEnd) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setDataDetails([...dataDetails].map(object => {
                      if (object.id === currentItem.id) {
                        return {
                          ...object,
                          dateEnd: date,
                        }
                      } else return object
                    }))
                    !date ? setPeriodDateEndError(true) : setPeriodDateEndError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={currentItem.dateStart ? new Date(currentItem.dateStart) : null}
                  readOnly={!editMode || !currentItem.dateStart}
                  placeholderText="End of period"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item short ${awardDateEndError ? 'error' : ''}`}>
              <label className="profile__form__label">Award Date<span> *</span></label>
              <div className={`profile__form__date ${!currentItem.dateStart && 'profile__form__date--readonly'}`}>
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  selected={currentItem.awardDate ? new Date(currentItem.awardDate) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setDataDetails([...dataDetails].map(object => {
                      if (object.id === currentItem.id) {
                        return {
                          ...object,
                          awardDate: date,
                        }
                      } else return object
                    }))
                    !date ? setAwardDateEndError(true) : setAwardDateEndError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={currentItem.dateStart ? new Date(currentItem.dateStart) : null}
                  readOnly={!editMode || !currentItem.dateStart}
                  placeholderText="Enter award date"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item long`}>
              <label className="profile__form__label">Notes</label>
              <textarea
                className="profile__form__textarea"
                placeholder={`${editMode ? 'Write notes' : 'Not indicated'}`}
                readOnly={!editMode}
                value={currentItem.notes?currentItem.notes:''}
                onChange={(e) => {
                  setDataDetails([...dataDetails].map(object => {
                    if (object.id === currentItem.id) {
                      return {
                        ...object,
                        notes: e.target.value,
                      }
                    } else return object
                  }))
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
        </div>
      </>
    )
  }

export default memo(ProjectsDetailsTabItem)