import { useState } from "react";

const PermissionSwitcher = ({ active, title, onChange,disabled=false }: { active: boolean, title: string, onChange?: (value) => void,disabled?:boolean }) => {
  const [isActive, setIsActive] = useState<string>(active ? "active" : "");
  const onSwitchClick = (e) => {
    if(!disabled ){
      setIsActive(prevVal => {
        return prevVal === 'active' ? '' : 'active'
      })
      onChange && onChange(isActive === 'active' ? false : true)
    }
  }

  return (
    <div className="permissions-switcher">
      <div className={`permissions-switcher__body ${isActive ? 'active' : ''}`} onClick={onSwitchClick}> </div>
      <div className="permissions-switcher__text">{title}</div>
    </div>
  )
}

export default PermissionSwitcher