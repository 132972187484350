export interface PasswordI {
  (password: string,
  setMinLength: any,
  setOneNumber: any,
  setAzSmallSymbol: any,
  setAzBigSymbol: any,
  setSpecialCharacters: any) : void
}

const minLengthPattern = /\d/g
const azSmallSymbolPattern = /[a-z]/
const azBigSymbolPattern = /[A-Z]|[\u0080-\u024F]/
const specialCharactersPattern = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/

const sbsPasswordCheckForErrors:PasswordI = (password, setMinLength, setOneNumber, setAzSmallSymbol, setAzBigSymbol, setSpecialCharacters) :void => {
  if (password.length >= 8) {
    setMinLength(true)
  } else {
    setMinLength(false)
  }

  if (password.match(minLengthPattern)) {
    setOneNumber(true)
  } else {
    setOneNumber(false)
  }
  if (password.match(azSmallSymbolPattern)) {
    setAzSmallSymbol(true)
  } else {
    setAzSmallSymbol(false)
  }

  if (password.match(azBigSymbolPattern)) {
    setAzBigSymbol(true)
  } else {
    setAzBigSymbol(false)
  }
  if (password.match(specialCharactersPattern)) {
    setSpecialCharacters(true)
  } else {
    setSpecialCharacters(false)
  }
}

export default sbsPasswordCheckForErrors