import '../../styles/_access-popup.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import Loader from '../Loader'

interface DataItem {
  title: string,
  description: string
}

interface Data {
  accessItems: Array<DataItem>
}

const ModuleAccessPopup = ({ setDisplayAccessPopup }) => {
  const [loading, setLoading] = useState(true)
  const [roleData, setRoleData] = useState<Data>({ accessItems: [] })

  useEffect(() => {
    (async () => {
      try {
        await axios.get('https://cms-dev.urecruits.com/access-modules-popup')
          .then(res => {
            if (res.status === 200 && res.data.accessItems.length > 0) {
              setLoading(false)
              setRoleData(res.data)
            } else {
              setLoading(true)
            }
          })
      } catch (e) {
        console.error('Fetch error', e)
      }
    })()
  }, [])

  const onClosePopup = () => {
    setDisplayAccessPopup(false)
  }

  return (
    <div className="access-popup">
      <div className="access-popup__inner">
        <div className="access-popup__head">
          <p className="access-popup__head__title">
            Module access
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="access-popup__head__close"
            onClick={onClosePopup}
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="access-popup__body">
          {
            loading ?
              <Loader/>
              :
              <>
                <div className="access-popup__body__head">
                  <p className="access-popup__body__tagline">
                    Module
                  </p>
                </div>
                <ul className="access-popup__info">
                  {
                    roleData.accessItems.map((item, index) => {
                      return (
                        <li className="access-popup__info__item" key={index}>
                          <p className="access-popup__info__headline">
                            {item.title}
                          </p>
                          <p className="access-popup__info__description">
                            {item.description}
                          </p>
                        </li>
                      )
                    })
                  }
                </ul>
              </>
          }
        </div>
      </div>
    </div>
  )
}

export default ModuleAccessPopup