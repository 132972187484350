import MembersTop from './MembersTop'
import MembersTableHead from './MembersTableHead'
import MembersTableBody from './MembersTableBody'
import MembersBottom from './MembersBottom'
import DeleteUserPopup from '../DeleteUserPopup'
import { memo, useEffect, useState } from 'react'
import ChangeRolePopup from '../ChangeRolePopup'
import fetchData from '../../../../hook/fetchData'
import { IPopupData } from '../../../../types/acess-tab/members-table'
import { getEnv } from "@urecruits/api"
import { AuthGuard, useHasPermission } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const MembersTable = ({ activeRole, setDataRefetch,  }) => {
  const {checkUserPermission} = useHasPermission()
  const [loading, setLoading] = useState(true)
  const [membersRefetch, setMembersRefetch] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [limit, setLimit] = useState(3)
  const [offset, setOffset] = useState(0)
  const [pagesCount, setPagesCount] = useState(0)
  const [sortBy, setSortBy] = useState('id')
  const [sortType, setSortType] = useState('desc')
  //TODO: need to add interface for membersList
  const [membersList, setMembersList] = useState<any>([])

  const [deleteUserPopup, setDeleteUserPopup] = useState({
      visible: false,
      userName: '',
      userId: 0
    }
  )

  const [rolesPopupData, setRolesPopupData] = useState<IPopupData>({
    visible: false,
    userName: '',
    userAuthId: '',
    prevRoles: [
      {
        value: '',
        label: ''
      }
    ],
    newRoles: [
      {
        value: '',
        label: ''
      }
    ]
  })
  const {API_RECRUITMENT} = getEnv()

  useEffect(() => {
    setLoading(true)
    fetchData(`${API_RECRUITMENT}/api/company/iam/users?roleId=${activeRole.authId}&limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortType=${sortType}&searchValue=${searchValue}`, setMembersList).then()
    setLoading(false)
  }, [activeRole, limit, offset, searchValue, sortBy, sortType, membersRefetch])
  useEffect(() => {
    setPagesCount(Math.ceil(membersList?.count / limit))
  }, [membersList])

  return (
    <AuthGuard module='team-members' permission={['view','delete']}>
      <div className="members">
        <MembersTop
          setOffset={setOffset}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          membersCount={membersList?.count}
        />
        <table className={searchValue===''?"members__table":"members__table members__table--radius"}>
          <MembersTableHead
            setSortBy={setSortBy}
            setSortType={setSortType}
            sortType={sortType}
            searchValue={searchValue}
            membersList={membersList}
          />
          <MembersTableBody
            setDeleteUserPopup={setDeleteUserPopup}
            deleteUserPopup={deleteUserPopup}
            membersList={membersList}
            setRolesPopupData={setRolesPopupData}
            rolesPopupData={rolesPopupData}
            loading={loading}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />

        </table>
        {membersList?.count > 0 && (<MembersBottom
          pagesCount={pagesCount}
          setLimit={setLimit}
          limit={limit}
          setOffset={setOffset}
        />)}
        {
          deleteUserPopup.visible && checkUserPermission('team-members','delete') && (
            <DeleteUserPopup
              setDeleteUserPopup={setDeleteUserPopup}
              deleteUserPopup={deleteUserPopup}
              setMembersRefetch={setMembersRefetch}
              setDataRefetch={setDataRefetch}
            />
          )
        }
        {
          rolesPopupData?.visible && checkUserPermission('team-members','edit') && (
            <ChangeRolePopup
              setRolesPopupData={setRolesPopupData}
              rolesPopupData={rolesPopupData}
              setMembersRefetch={setMembersRefetch}
              setDataRefetch={setDataRefetch}
            />
          )
        }
      </div>
    </AuthGuard>
  )
}
export default memo(MembersTable)