import axios from "axios";

const fetchData = async (api,setData) => {
  const token: string = localStorage.getItem('token')
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  const res = await axios.get(api, config)
  if (res && res.data) {
    setData(res.data)

    return res
  }
}
export default fetchData