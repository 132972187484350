import IntegrationsCard from "./IntegrationCard";
import { memo, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { Item } from "react-photoswipe-gallery";
const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

//TODO: need to document function
const prepareData = (strapiData, apiData, category) => {
  return strapiData
    .map((item) => {
      const findItem = apiData.find((x) => x.key === item.unique_id);
      return {
        id: item.unique_id,
        name: item.name,
        description: item.description,
        image: item.image.url,
        integration_category: {
          id: item.integration_category.id,
          name: item.integration_category.name,
        },
        status: item.status,
        connectUrl: findItem?.connectUrl || "https://urecruits.com/",
        key: findItem?.key || "",
      };
    })
    .filter((x) => x.integration_category.name === category.name);
};

const IntegrationItem = ({
  category,
  integrations,
  connectionIntegrations,
  setIntegrations,
}) => {
  const [display, setDisplay] = useState(false);

  return (
    <div className="integrations__item">
      <div
        className="integrations__head"
        onClick={() => setDisplay((prev) => !prev)}
      >
        <p className="integrations__head__name">{category.name}</p>
        <div>
          {category?.name === "Email & Calendar" && display && (
            <>
              <a
                href="https://urecruits.com/disclosure"
                className="integrations__card__integration-button__button button--filled"
                style={{ marginRight: "10px" }}
              >
                View Disclosure
              </a>
            </>
          )}
          <MemoSvg display={display} />
        </div>
      </div>

      {display && (
        <div className="integrations__body">
          {prepareData(integrations, connectionIntegrations, category).map(
            (item, index) => {
              if (item?.name !== "Gmail") {
                return (
                  <IntegrationsCard cardItem={item} key={index} index={index} />
                );
              }
            }
          )}
        </div>
      )}
    </div>
  );
};
export default memo(IntegrationItem);



const MemoSvg = memo(({display}: { display: boolean }) => {
	return (
		<svg
			className={`integrations__head__close ${display ? "active" : ""}`}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M21.5996 7.20005L11.9996 16.8L2.39961 7.20005" stroke="#C1C5CB" strokeWidth="2"
			      strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	);
});