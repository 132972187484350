import axios from "axios";

const putData = async (api,data) => {
  const token: string = localStorage.getItem('token')
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  return await axios.put(api, data, config)
}
export default putData