import MembersTableRow from './MembersTableRow'
import React, { memo } from 'react'
import EmptySearch from './EmptySearch'

const MembersTableBody = ({
  setDeleteUserPopup,
  deleteUserPopup,
  membersList,
  rolesPopupData,
  setRolesPopupData,
  loading,
  setSearchValue,
  searchValue
}) => {

  return (
    <tbody className="members__tbody">
    {
      membersList.rows?.map((item) => {
        return (
          <MembersTableRow
            setDeleteUserPopup={setDeleteUserPopup}
            deleteUserPopup={deleteUserPopup}
            rolesPopupData={rolesPopupData}
            setRolesPopupData={setRolesPopupData}
            item={item}
            key={item.id}
          />
        )
      })
    }
    {
      !loading && membersList?.count === 0 && searchValue.length > 0 &&(
        <EmptySearch
          searchValue={searchValue}
          flag={false}
          setSearchValue={setSearchValue}
        />
      )
    }
    {
      !loading && membersList?.count === 0 && searchValue === "" && (
        <EmptySearch
          searchValue={searchValue}
          flag={true}
          setSearchValue={setSearchValue}
        />
      )
    }
    </tbody>
  )
}
export default memo(MembersTableBody)