import QuickViewTab from '../component/recruiter/QuickViewTab'
import { useEffect, useLayoutEffect, useState } from 'react'
import PopupPictureUpload from '../component/recruiter/PopupPictureUpload'
import PersonalDetailsTab from '../component/recruiter/PersonalDetailsTab'
import { default as ReactSelect } from 'react-select'
import { selectMobileMenuStyle } from '../styles/selectMobileMenuStyle'
import ManagePasswordTab from '../component/recruiter/ManagePasswordTab'
import VisaInformationTab from '../component/recruiter/VisaInformationTab'
import EducationsDetailsTab from '../component/recruiter/educations/EducationsDetailsTab'
import ExperienceDetailsTab from '../component/recruiter/experience/ExperienceDetailsTab'
import fetchData from '../hook/fetchData'
import { getEnv } from '@urecruits/api'
import ModuleAccessPopup from '../component/company/ModuleAccessPopup'
import AccessManagemantTab from '../component/company/access/AccessManagementTab'
import CompanyDetail from '../component/company/detail/CompanyDetail'
import SubscriptionTab from '../component/company/subscription/SubscriptionTab'
import Integrations from '../component/company/integrations/Integrations'
import PopupGalleryUpload from "../component/company/PopupGalleryUpload"
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
const stripePromise = loadStripe('pk_test_51KToKtBX9fRLZIirWZdzZwegbQWZu0u2NCyblWrJh6DhYqyoFEjmAtTlJF0n2naWlWmZA2wY1BXAhCPHyaTvzVGC00Pzzn7Y4A')

const menuOption: any = [
  { value: 'quickView', label: 'Quick View' },
  { value: 'personalDetails', label: 'Personal Details' },
  { value: 'educationsDetails', label: 'Educations Details' },
  { value: 'experienceDetails', label: 'Experience Details' },
  { value: 'visaInformation', label: 'Visa Information' },
  { value: 'managePassword', label: 'Manage Password' },
]

const RecruiterProfileScreen = ({ usState, countryList, nationalityList, languagesList, positionIndustryList }) => {
  const { checkUserPermission } = useHasPermission()
  const [activeTabName, setActiveTabName] = useState('quickView')
  const [displayUploadPopup, setDisplayUploadPopup] = useState(false)
  const [editStatusForPopup, setEditStatusForPopup] = useState(false)
  const [displayGalleryPopup, setDisplayGalleryPopup] = useState(false)
  const [data, setData] = useState(null)
  const [dataCompany, setDataCompany] = useState(null)
  const [refetch, setRefetch] = useState('')
  const [refetchCompany, setRefetchCompany] = useState('')
  const [displayAccessPopup, setDisplayAccessPopup] = useState(false)
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false)
  const {API_RECRUITMENT} = getEnv()

  useLayoutEffect(() => {
    if (checkUserPermission('company-admin') || checkUserPermission('team-members', 'view')) {
      setIsCompanyAdmin(true)
    }
  }, [])
  const companyAdminMenu: any = [
    { value: 'quickView', label: 'Quick View' },
    { value: 'personalDetails', label: 'Personal Details' },
    { value: 'educationsDetails', label: 'Educations Details' },
    { value: 'experienceDetails', label: 'Experience Details' },
    { value: 'visaInformation', label: 'Visa Information' },
    { value: 'managePassword', label: 'Manage Password' },
    {...(checkUserPermission('company-profile',['view','edit']) ? { value: 'companyDetails', label: 'Company Details' } : {})},
    {...(checkUserPermission('team-members',['view','edit','delete']) ? { value: 'accessManagement', label: 'Access Management' } : {})},
    {...(checkUserPermission('subscription',['view','edit','add','delete']) ? { value: 'subscriptions', label: 'Subscriptions' } : {})},
    { value: 'integrations', label: 'Integrations' },
  ]

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/recruiter`, setData).then()
  }, [refetch, refetch])

  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/company`, setDataCompany).then()

  }, [refetchCompany])

  return (
    <AuthGuard module={['recruiter','company-admin']}>
      <section className="profile">
        <div className="profile__inner">
          <h2 className="profile__headline">
            {
              isCompanyAdmin ?
                'Company Management'
                :
                'Recruiter Profile'
            }
          </h2>
          <div className="profile__left">
            <ul className="profile__left__menu">
              <li
                className={`profile__left__item ${activeTabName === 'quickView' ? 'active' : ''}`}
                onClick={() => setActiveTabName('quickView')}
              >
                Quick View
              </li>
              <li
                className={`profile__left__item ${activeTabName === 'personalDetails' ? 'active' : ''}`}
                onClick={() => setActiveTabName('personalDetails')}
              >
                Personal Details
              </li>
              <li
                className={`profile__left__item ${activeTabName === 'educationsDetails' ? 'active' : ''}`}
                onClick={() => setActiveTabName('educationsDetails')}
              >
                Educations Details
              </li>
              <li
                className={`profile__left__item ${activeTabName === 'experienceDetails' ? 'active' : ''}`}
                onClick={() => setActiveTabName('experienceDetails')}
              >
                Experience Details
              </li>
              <li
                className={`profile__left__item ${activeTabName === 'visaInformation' ? 'active' : ''}`}
                onClick={() => setActiveTabName('visaInformation')}
              >
                Visa Information
              </li>
              {
                isCompanyAdmin && <>
                  <AuthGuard module='company-profile' permission={['view', 'edit']}>
                    <li
                      className={`profile__left__item ${activeTabName === 'companyDetails' ? 'active' : ''}`}
                      onClick={() => setActiveTabName('companyDetails')}
                    >
                      Company Details
                    </li>
                  </AuthGuard>
                  <AuthGuard module='team-members' permission={['view', 'edit', 'delete']}>
                    <li
                      className={`profile__left__item ${activeTabName === 'accessManagement' ? 'active' : ''}`}
                      onClick={() => setActiveTabName('accessManagement')}
                    >
                      Access Management
                    </li>
                  </AuthGuard>
                  <AuthGuard module='subscription' permission={['view', 'add', 'edit', 'delete']}>
                    <li
                      className={`profile__left__item ${activeTabName === 'subscriptions' ? 'active' : ''}`}
                      onClick={() => setActiveTabName('subscriptions')}
                    >
                      Subscriptions
                    </li>
                  </AuthGuard>
                  <li
                    className={`profile__left__item ${activeTabName === 'integrations' ? 'active' : ''}`}
                    onClick={() => setActiveTabName('integrations')}
                  >
                    Integrations
                  </li>
                </>
              }
              <li
                className={`profile__left__item ${activeTabName === 'managePassword' ? 'active' : ''}`}
                onClick={() => setActiveTabName('managePassword')}
              >
                Manage Password
              </li>
            </ul>
            <div className="profile__left__mobile-menu">
              <ReactSelect
                options={isCompanyAdmin ? companyAdminMenu : menuOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setActiveTabName(item.value)
                }}
                defaultValue={{ label: 'Quick View', value: 0 }}
                styles={selectMobileMenuStyle}
                id="genderSelect"
                instanceId="genderSelect"
              />
            </div>
          </div>
          <div className={`profile__right ${activeTabName === 'managePassword' ? 'password' : ''}`}>
            {
              activeTabName === 'companyDetails' && (
                <CompanyDetail
                  data={dataCompany}
                  countryList={countryList}
                  setRefetch={setRefetchCompany}
                  setDisplayUploadPopup={setDisplayUploadPopup}
                  setEditStatusForPopup={setEditStatusForPopup}
                  setDisplayGalleryPopup={setDisplayGalleryPopup}
                />
              )
            }
            {
              activeTabName === 'subscriptions' && (
                <Elements stripe={stripePromise}>
                <SubscriptionTab />
                </Elements>
              )
            }
            {
              activeTabName === 'quickView' && (
                <QuickViewTab setDisplayUploadPopup={setDisplayUploadPopup} data={data}
                  setRefetch={setRefetch} setEditStatusForPopup={setEditStatusForPopup} />
              )
            }
            {
              activeTabName === 'personalDetails' && (
                <PersonalDetailsTab data={data} setRefetch={setRefetch} usState={usState}
                  countryList={countryList} languagesList={languagesList} />
              )
            }
            {
              activeTabName === 'managePassword' && (
                <ManagePasswordTab data={data} />
              )
            }
            {
              activeTabName === 'visaInformation' && (
                <VisaInformationTab data={data} setRefetch={setRefetch} countryList={countryList}
                  nationalityList={nationalityList} />
              )
            }
            {
              activeTabName === 'educationsDetails' && (
                <EducationsDetailsTab data={data} setRefetch={setRefetch} />
              )
            }
            {
              activeTabName === 'experienceDetails' && (
                <ExperienceDetailsTab data={data} setRefetch={setRefetch} countryList={countryList}
                  positionIndustryList={positionIndustryList} />
              )
            }
            {
              activeTabName === 'accessManagement' && (
                <AccessManagemantTab setDisplayAccessPopup={setDisplayAccessPopup} />
              )
            }
            {
              activeTabName === 'integrations' && (
                <Integrations />
              )
            }
          </div>
        </div>
      </section>
      {displayUploadPopup && editStatusForPopup && (
        <PopupPictureUpload setDisplayUploadPopup={setDisplayUploadPopup} data={data} setRefetch={setRefetch} />
      )}
      {
        displayGalleryPopup && (
          <PopupGalleryUpload
            setDisplayGalleryPopup={setDisplayGalleryPopup}
            setRefetch={setRefetch}
            userData={data}
          />
        )
      }
      {displayAccessPopup && checkUserPermission('team-members','view') && (
        <ModuleAccessPopup
          setDisplayAccessPopup={setDisplayAccessPopup}
        />
      )}
    </AuthGuard>
  )
}

export default RecruiterProfileScreen