import { AuthGuard, ShowToolTipComponent } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import SelectRoles from '../../../SelectRoles/SelectRoles'
import { useState, memo } from 'react'
import { useHasPermission } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import convertDataToString from '../../../../hook/convertDataToString'
const deleteIcon = require('../../../../image/icon/delete_ic.svg')
const eyeIcon = require('../../../../image/icon/eye_icon.svg')
const userAvatar = require('../../../../image/icon/avatar.svg')

const MembersTableRow = ({
  setDeleteUserPopup,
  deleteUserPopup,
  item,
  rolesPopupData,
  setRolesPopupData
}) => {
  const {checkUserPermission} = useHasPermission()
  const [mobileToggle, setMobileToggle] = useState(false)

  const onUserDeleteHandler = () => {
    setDeleteUserPopup({
      ...deleteUserPopup,
      visible: true,
      userName: `${item.firstname} ${item.lastname}`,
      userId: item.id
    })
  }
  return (
    <tr className={`members__tbody__tr ${mobileToggle ? 'active' : ''}`}>
      <td
        className="members__tbody__td name"
        onClick={() => setMobileToggle(!mobileToggle)}
      >
        <div className="members__user">
          <img src={item.avatar || userAvatar} alt="user name" className="members__user__avatar"/>
          <p className="members__user__name">{item.firstname} {item.lastname}</p>
        </div>
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="members__tbody__arrow"
        >
          <g clipPath="url(#clip0_10382_100293)">
            <path d="M1 3L5 7L9 3" stroke="#999EA5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_10382_100293">
              <rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)"/>
            </clipPath>
          </defs>
        </svg>
      </td>
      <td className="members__tbody__td id">
        <p className="mobile-headline">
          ID:
        </p>
        <p className="id__value">
          {item.id}
        </p>
      </td>
      <td className="members__tbody__td job">
        <p className="mobile-headline">
          Job Title:
        </p>
        <p className="job__value">
          {item.recruiter?.position?.label}
        </p>
      </td>
      <td className="members__tbody__td role">
        <p className="mobile-headline">
          Role:
        </p>
        {
          checkUserPermission('team-members','edit') ?
          <SelectRoles
          currentUserData={item}
          rolesPopupData={rolesPopupData}
          setRolesPopupData={setRolesPopupData}
          />
          :
            <p className="role__value">
              {convertDataToString(item.roles)}
            </p>
       }
      </td>
      <td className="members__tbody__td actions">
        <p className="mobile-headline">
          Actions:
        </p>
        <div className="members__action">
          <AuthGuard module='team-members' permission='view'>
            <ShowToolTipComponent text='View Profile'>
          <div className="members__action__item show">
            <img src={eyeIcon} alt="eye icon" className="members__action__icon"/>
            {/* <div className="members__action__info">
              <p>Show profile</p>
              </div> */}
          </div>
              </ShowToolTipComponent>
          </AuthGuard>
          <AuthGuard module='team-members' permission='delete'>
            <ShowToolTipComponent text='Delete'>
          <div
            className="members__action__item"
            onClick={onUserDeleteHandler}
            >
            <img src={deleteIcon} alt="eye icon" className="members__action__icon"/>
          </div>
            </ShowToolTipComponent>
          </AuthGuard>
          </div>
      </td>
    </tr>
  )
}
export default memo(MembersTableRow)