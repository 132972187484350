import {memo} from "react";

const visaIcon = require('../../../image/icon/visa_ic.svg')
const visaBlackIcon = require('../../../image/icon/visa_black_ic.svg')
const mastercardIcon = require('../../../image/icon/mastercard_ic.svg')
const americanExpressIcon = require('../../../image/icon/aexpress_icon.svg')
const discoverIcon = require('../../../image/icon/discover_icon.svg')
const dinnersIcon = require('../../../image/icon/diners_ic.svg')
const jcbIcon = require('../../../image/icon/jcb_ic.svg')
const unionIcon = require('../../../image/icon/union_icon.svg')

const editIcon = require('../../../image/icon/edit_green_ic.svg')
const deleteIcon = require('../../../image/icon/delete_ic.svg')

const PaymentCardItem = ({
  cardData,
  displayCardId,
  setDisplayCardId,
  setEditMode,
  editMode,
  setAddedMore,
  addedMore,
  setDeleteCardPopup,
  deleteCardPopup,
  emptyCard,
}) => {

  const onEditCardHandler = () => {
    setEditMode(!editMode)
  }

  const onDeleteCardHandler = async () => {
    setDeleteCardPopup({ ...deleteCardPopup, visible: true, cardId: displayCardId, defaultCard: cardData.default })
  }

  const choseActiveCard = () => {
    setAddedMore(false)
    setDisplayCardId(cardData.id)
  }

  return (
    <div
      className={`payment-card ${cardData.id === displayCardId && !addedMore ? 'active' : ''} ${emptyCard ? 'empty' : ''}`}
      onClick={choseActiveCard}
    >
      <div className="payment-card__inner">
        <div className="payment-card__user">
          {cardData.billing_details.name}
        </div>
        <div className="payment-card__number">
          <p className="payment-card__number__title">
            Card number
          </p>
          <p className="payment-card__number__value">
            XXXX XXXX XXXX<span className="payment-card__number__value--last"> {cardData.last4}</span>
          </p>
        </div>
        <div className="payment-card__bottom">
          <div className="payment-card__term">
            <p className="payment-card__term__label">
              month/year
            </p>
            <div className="payment-card__term__value">
              <span>{cardData.expMonth}</span>/<span>{cardData.expYear}</span>
            </div>
          </div>
          <div className="payment-card__cv">
            <p className="payment-card__cv__label">
              cvv
            </p>
            <span className="payment-card__cv__value">***</span>
          </div>
          <div className="payment-card__brand">
            <div className="payment-card__brand__value">
              {
                cardData.brand === 'mastercard' ?
                  <img src={mastercardIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'amex' ?
                  <img src={americanExpressIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'discover' ?
                  <img src={discoverIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'unionpay' ?
                  <img src={unionIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'diners' ?
                  <img src={dinnersIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'jcb' ?
                  <img src={jcbIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'visa' && cardData.id !== displayCardId ?
                  <img src={visaBlackIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
              {
                cardData.brand === 'visa' && cardData.id === displayCardId && !addedMore ?
                  <img src={visaIcon} alt="card brand" className="payment-card__brand__image"/>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
      {
        (displayCardId && displayCardId === cardData.id) && !addedMore && (
          <div className="payment-card__action">
            <div className="payment-card__action__edit" onClick={onEditCardHandler}>
              <img src={editIcon} alt="action icon" className="payment-card__action__icon"/>
              <p className="payment-card__action__text">Edit</p>
            </div>
            <div className="payment-card__action__edit" onClick={onDeleteCardHandler}>
              <img src={deleteIcon} alt="action icon" className="payment-card__action__icon"/>
              <p className="payment-card__action__text">Delete</p>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default memo(PaymentCardItem)