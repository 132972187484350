import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css";
import "../styles/_react-quill.scss";
import {memo} from "react";

const modules = {
	toolbar: [
		[{header: [1, 2, 3, 4, 5, 6, false]}],
		["bold", "underline"],
		[{color: []}, {background: []}],
		[{list: "ordered"}, {list: "bullet"}],
		["link"],
	],
};

const RichTextEditor = ({value, setValue, placeholder, setValidate, validateFlag, editMode}) => {

	return (
		<ReactQuill
			theme="snow"
			value={value}
			modules={modules}
			onChange={(val) => {
				if (validateFlag) {
					val.length === 0 || val === "<p><br></p>" ? setValidate(true) : setValidate(false);
				}
				setValue(val);
			}}
			readOnly={!editMode}
			placeholder={placeholder}
			bounds={"#quill-container"}
			className="react-quill"
		>
			<div style={{minHeight: 200}} id="quill-container"/>
		</ReactQuill>
	);
};

export default memo(RichTextEditor);