import { TableCardViewComp } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'
import { IColumns } from '@ucrecruits/globalstyle/types/table-types'
import { getEnv } from '@urecruits/api'
import DownloadSVG from '../../../image/icon/download_ic.svg'
const { API_RECRUITMENT } = getEnv()

const InvoiceHistoryComponent = () => {

  let currentDate = new Date();

  let lastMonthDate = new Date();
  lastMonthDate.setMonth(currentDate.getMonth() - 1);

  const columns: Array<IColumns> = [
    {
      field: 'invoiceOnDate',
      headerName: "Invoice Date",
      visibility: true,
      isMobileTitle: true,
      renderCell(cellValue, rowData) {
        const date = new Date(cellValue)
        return <p>{date.toLocaleDateString()}</p>
      },
      mobileRenderCell(cellValue, rowData) {
        const date = new Date(cellValue)
        return <p>{date.toLocaleDateString()}</p>
      },
    },
    {
      field: 'status',
      visibility: true,
      headerName: "Status",
      renderCell(cellValue, rowData) {
        const value = cellValue === 'paid' ? 'green' : cellValue === 'In progress' ? 'red' : 'yellow'
        return <p className={`table__status-block--${value} invoices__inner__status`}>
          {cellValue}
        </p>
      },
      mobileRenderCell(cellValue, rowData) {
        const value = cellValue === 'paid' ? 'green' : cellValue === 'In progress' ? 'red' : 'yellow'
        return <p className={`table__status-block--${value} invoices__inner__status`}>
          {cellValue}
        </p>
      },
    },
    {
      field: 'amount',
      visibility: true,
      headerName: "Amount",
      renderCell: (cell, row) => {
        return `$${cell ? (cell / 100).toFixed(2) : 0}`;
      },
      mobileRenderCell(cellValue, rowData) {
        return `$${cellValue ? (cellValue / 100).toFixed(2) : 0}`;
      },
    },
    {
      field: 'actions',
      visibility: true,
      headerName: "Actions",
      setActions: [
        {
          getAction: (row) => {
            if(row.status !== 'paid'){
              return
            }
            return {
              svg: <a href={`${row.invoicePdf}`}><img src={DownloadSVG} alt='download invoice image' /></a>,
              title: 'Download Invoice',
              onClick: (row, event) => {

              },
              className: 'active'
            }
          }
        }
      ],
    },
  ]

  return (
    <div>
      <div className="invoices__head">
        <p className="invoices__head__title">Invoices</p>
      </div>
      <div className="invoices__inner">
        <TableCardViewComp
          columns={columns}
          listAPI={`${API_RECRUITMENT}/api/payment/company/get-invoices?dateFrom=${currentDate}`}
        />
      </div>
    </div>
  )
}

export default InvoiceHistoryComponent
