import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { memo } from "react";

const SubscriptionCard = ({
  setTabDisplay,
  tabDisplay,
  cardData,
  period,
  setSubscriptionData,
  subscriptionData,
  currentSubscription,
  cancelSubscriptionData,
  setCancelSubscriptionData,
}) => {
  const { checkUserPermission } = useHasPermission()
  const onChangeSubscriptionData = () => {
    let data: any = {}
    if (period === 'monthly') {
      data.priceId = cardData.prices.month[0].id
      data.planId = cardData.name?.split(" ")[1]
      data.duration = 'm';
    } else {
      data.priceId = cardData.prices.year[0]?.id
      data.planId = cardData.name?.split(" ")[1]
      data.duration = 'y';
    }
    setSubscriptionData({ ...subscriptionData, popupVisible: true, ...data })
  }

  const currentPlanDisplay = () => {
    if (period === 'monthly' && currentSubscription.subId !== 'noSubscription') {
      return cardData.prices.month[0].id === currentSubscription.priceId
    }
    if (period === 'yearly' && currentSubscription.subId !== 'noSubscription') {
      return cardData.prices.year[0].id === currentSubscription.priceId
    }
  }

  const cancelSubscription = async () => {
    setCancelSubscriptionData({ ...cancelSubscriptionData, popupVisible: true, subId: currentSubscription.subId })
  }

  const convertCentToDollar = (value) => {
    return value / 100
  }

  return (
    <AuthGuard module='subscription' permission='view'>
      <AuthGuard module='subscription' permission={['edit', 'add']}>
        <div className="subscription-card">
          <div className="subscription-card__inner">
            <p className="subscription-card__title">
              {cardData.name}
            </p>
            {
              period === 'monthly' && (
                <p className="subscription-card__price">
                  ${convertCentToDollar(cardData.prices.month[0].unit_amount)}<span>/month</span>
                </p>
              )
            }
            {
              period === 'yearly' && (
                <p className="subscription-card__price">
                  ${convertCentToDollar((cardData.prices.year[0].unit_amount/12))}<span>/month</span>
                </p>
              )
            }
            <span className="subscription-card__period">Per month</span>
            {
              currentPlanDisplay() ?
                <div className="subscription-card__choose-plan button--empty current-plan">
                  Current plan
                </div>
                : 
                checkUserPermission(['subscription', 'payment-integration'], ['add'], 'AND') ?
                <div
                  className="subscription-card__choose-plan button--empty button--empty-disable"
                  onClick={() => onChangeSubscriptionData()}
                >
                  {currentSubscription.subId!=='noSubscription' ? "Upgrade Plan" :"Get Started"}
                </div>
                :<></>
            }
            {
              tabDisplay === 'current' && currentSubscription.subId !== 'noSubscription' && checkUserPermission('subscription', 'delete') && (
                <p className="subscription-card__cancel" onClick={cancelSubscription}>Cancel Subscription</p>
              )
            }
            <ul className="subscription-card__list">
              {
                cardData.features.map((value, index) => {
                  return (
                    <li className="subscription-card__list__item" key={index}>
                      <div className="subscription-card__list__item--icon"></div>
                      <p className="subscription-card__list__item--value">
                        {value}
                      </p>
                    </li>
                  )
                })
              }
            </ul>
            {
              tabDisplay == 'current' && (
                <p className="subscription-card__another" onClick={() => setTabDisplay('packages')}>
                  See another plans
                </p>
              )
            }
            {
              tabDisplay == 'packages' && (
                <a className="subscription-card__another" href="https://urecruits.com/pricing" target="_blank">
                  See more about plan
                </a>
              )
            }
          </div>
        </div>
      </AuthGuard>
    </AuthGuard>
  )
}

export default memo(SubscriptionCard)