import DatePicker from 'react-datepicker'
import {memo, useEffect, useState} from 'react'
import axios from "axios";
import {getEnv} from "@urecruits/api";

const deleteIc = require('../../../image/icon/delete_ic.svg')

const EducationsDetailsTabItem =
  ({
     editMode,
     item,
     setEducationsDetails,
     educationsDetails,
     setValidateStatus,
     validateStatus,
     displayButton,
     addedMoreEducationButtonRef,
     submitButtonRef,
     index,
     recruiterId,
     setRefetch,
    setDisplayButton
   }) => {
    const token: string = localStorage.getItem('token')
    const [universityNameError, setUniversityNameError] = useState(false)
    const [courseNameError, setCourseNameError] = useState(false)
    const [specificationsError, setSpecificationsError] = useState(false)
    const [coursePeriodsStartError, setCoursePeriodsStartError] = useState(false)
    const [coursePeriodsEndError, setCoursePeriodsEndError] = useState(false)

    const fieldsValidate = () => {
      let error = 0
      educationsDetails.map((object) => {
        !object.universityName || object.universityName.length <= 0 ? setUniversityNameError(true) : setUniversityNameError(false)
        !object.courseName || object.courseName.length <= 0 ? setCourseNameError(true) : setCourseNameError(false)
        !object.specification || object.specification.length <= 0 ? setSpecificationsError(true) : setSpecificationsError(false)
        !object.coursePeriodStart ? setCoursePeriodsStartError(true) : setCoursePeriodsStartError(false)
        !object.coursePeriodEnd ? setCoursePeriodsEndError(true) : setCoursePeriodsEndError(false)

        if (!object.universityName || object.universityName.length <= 0 ||
          !object.specification || object.specification.length <= 0 ||
          !object.coursePeriodStart || !object.coursePeriodEnd ||
          !object.specification || object.specification.length <= 0) {
          error++
        }
      })

      if (error === 0) {
        setValidateStatus(true)
      }
    }

    useEffect(() => {
      if (!validateStatus && editMode) {
        //added more click validate
        addedMoreEducationButtonRef.current.onclick = function () {
          fieldsValidate()
        }

        //on submit click validate
        if (displayButton) {
          submitButtonRef.current.onclick = function () {
            fieldsValidate()
          }
        }
      }
    }, [validateStatus, educationsDetails, item, editMode])
    const {API_RECRUITMENT} = getEnv()

    const onRemoveItemHandler = async () => {
      if (editMode) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
        const data = {
          id: item.id,
          recruiterId: recruiterId,
        }
        await axios.delete(`${API_RECRUITMENT}/api/recruiter/education`, {headers, data}).then((res) => {
          setRefetch(new Date())
        })
      }
    }

    return (
      <>
        <div className="profile__form__part">
          {
            index !== 0 && (
              <div className={`profile__form__delete ${!editMode ? 'hide' : ''}`}>
                <div className="profile__form__delete--inner" onClick={onRemoveItemHandler}>
                  <span className="profile__form__delete--text">Delete</span>
                  <img src={deleteIc} alt="delete icon" className="profile__form__delete--icon"/>
                </div>
              </div>
            )
          }
          <div className="profile__form__group">
            <div className={`profile__form__item long ${universityNameError ? 'error' : ''}`}>
              <label className="profile__form__label">University/Institute Name<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Select University/Institute name' : 'Not indicated'}`}
                readOnly={!editMode}
                value={item.universityName?item.universityName:''}
                onChange={(e) => {
                  setEducationsDetails([...educationsDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        universityName: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setUniversityNameError(true) : setUniversityNameError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item  ${courseNameError ? 'error' : ''}`}>
              <label className="profile__form__label">Course Name<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter your course name' : 'Not indicated'}`}
                readOnly={!editMode}
                value={item.courseName?item.courseName:''}
                onChange={(e) => {
                  setEducationsDetails([...educationsDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        courseName: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setCourseNameError(true) : setCourseNameError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item  ${specificationsError ? 'error' : ''}`}>
              <label className="profile__form__label">Specification<span> *</span></label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter your specification' : 'Not indicated'}`}
                readOnly={!editMode}
                value={item.specification?item.specification:''}
                onChange={(e) => {
                  setEducationsDetails([...educationsDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        specification: e.target.value,
                      }
                    } else return object
                  }))
                  e.target.value.length <= 0 ? setSpecificationsError(true) : setSpecificationsError(false)
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item short after-line ${coursePeriodsStartError ? 'error' : ''}`}>
              <label className="profile__form__label">Course Periods<span> *</span></label>
              <div className="profile__form__date">
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={40}
                  selected={item.coursePeriodStart ? new Date(item.coursePeriodStart) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setEducationsDetails([...educationsDetails].map(object => {
                      if (object.id === item.id) {
                        return {
                          ...object,
                          coursePeriodStart: date,
                        }
                      } else return object
                    }))
                    !date ? setCoursePeriodsStartError(true) : setCoursePeriodsStartError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  readOnly={!editMode}
                  placeholderText="Start of course"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item short ${coursePeriodsEndError ? 'error' : ''}`}>
              <div className={`profile__form__date ${!item.coursePeriodStart&&'profile__form__date--readonly'}`}>
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={50}
                  selected={item.coursePeriodEnd ? new Date(item.coursePeriodEnd) : null}
                  onChange={(date: any) => {
                    setDisplayButton(true)
                    setEducationsDetails([...educationsDetails].map(object => {
                      if (object.id === item.id) {
                        return {
                          ...object,
                          coursePeriodEnd: date,
                        }
                      } else return object
                    }))
                    !date ? setCoursePeriodsEndError(true) : setCoursePeriodsEndError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  minDate={item.coursePeriodStart?new Date(item.coursePeriodStart) : null}
                  readOnly={!editMode||!item.coursePeriodStart}
                  placeholderText="End of course"
                />
              </div>
              <p className="error-message">This is required field</p>
            </div>
            <div className={`profile__form__item short`}>
              <label className="profile__form__label">GPA / Percentage</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter GPA / Percentage' : 'Not indicated'}`}
                readOnly={!editMode}
                value={item.gpa?item.gpa:''}
                onChange={(e) => {
                  setEducationsDetails([...educationsDetails].map(object => {
                    if (object.id === item.id) {
                      return {
                        ...object,
                        gpa: e.target.value,
                      }
                    } else return object
                  }))
                }}
              />
              <p className="error-message">This is required field</p>
            </div>
          </div>
        </div>
      </>
    )
  }

export default memo(EducationsDetailsTabItem)