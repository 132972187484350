export const stripeStyle = {
  style: {
    base: {
      lineHeight: '1',
      padding: '13px 16px',
      fontSize: '14px',
      color: '#2A2C33',
    },
    invalid: {
      color: '#9e2146',
    },
  },
}