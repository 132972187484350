import { memo, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { validatePhoneNumber } from "../../hook/validatePhoneNumber";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { selectDisableStyle } from "../../styles/selectDisableStyle";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { getEnv, GlobalConstants, selectSearchFuncLocationLimit } from "@urecruits/api";
import { currencyData } from "../../constant/currency";
import AsyncSelect from "react-select/async";
import {
	selectSearchFuncIndustry,
	selectSearchFuncPositionLimit,
} from "../../hook/selectSearchFunc";
import patchData from "../../hook/patchData";
import { Stack, Typography, Box } from "@mui/material";
// import { SelectLocationComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const userAvatar = require("../../image/icon/avatar.svg");
const cameraIc = require("../../image/icon/camera_ic.svg");
const editIc = require("../../image/icon/edit_ic.svg");

const totalExperienceOption = [
	{value: "1", label: "1"},
	{value: "2", label: "2"},
	{value: "3", label: "3"},
	{value: "4", label: "4"},
	{value: "5", label: "5"},
	{value: "6", label: "6"},
	{value: "7", label: "7"},
	{value: "8", label: "8"},
	{value: "9", label: "9"},
	{value: "10", label: "10"},
	{value: "10-15", label: "10-15"},
	{value: "15-20", label: "15-20"},
	{value: "20-25", label: "20-25"},
	{value: "25-30", label: "25-30"},
	{value: "30-35", label: "30-35"},
	{value: "35-40", label: "35-40"},
	{value: "40-45", label: "40-45"},
	{value: "45-50", label: "45-50"},
];

const educationOption = [
	{
		value: "High School",
		label: "High School",
	},
	{
		value: "Associate degree",
		label: "Associate degree",
	},
	{
		value: "Bachelor's degree",
		label: "Bachelor's degree",
	},
	{
		value: "Master's degree",
		label: "Master's degree",
	},
	{
		value: "Doctorate degree",
		label: "Doctorate degree",
	},
	{
		value: "Professional degree",
		label: "Professional degree",
	},
];

const Option = (props: any) => {

	return (
		<div>
			<components.Option {...props} className="profile__form__checkbox">
				<input
					id={props.value}
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label htmlFor={props.value}><span/></label>
				<p>{props.label}</p>
			</components.Option>
		</div>
	);
};

const {API_RECRUITMENT} = getEnv();


const QuickViewTab = ({setDisplayUploadPopup, data, setData, setRefetch, positionIndustryList, setEditStatusForPopup}) => {
	const [success, setSuccess] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [displayButton, setDisplayButton] = useState(false);
	const {phoneValidationLength} = GlobalConstants();

	//form fields state
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [position, setPosition] = useState({label: "", value: "", id: ""});
	const [experience, setExperience] = useState({label: "", value: ""});
	const [personalEmail, setPersonalEmail] = useState("");
	const [phone, setPhone] = useState(null);
	const [industries, setIndustries] = useState([]);
	const [location, setLocation] = useState({label: "", value: ""});
	const [currentCtc, setCurrentCtc] = useState("");
	const [currency, setCurrency] = useState("");
	const [headlines, setHeadlines] = useState("");
	const [summary, setSummary] = useState("");
	const [avatar, setAvatar] = useState(userAvatar);
	const [education, setEducation] = useState({label: "", value: ""});
	const [remoteLocationCheckbox, setRemoteLocationCheckbox] = useState(false);

	//error
	const [firstNameError, setFirstNameError] = useState(false);
	const [middleNameError, setMiddleNameError] = useState(false);
	const [lastNameError, setLastNameError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [locationError, setLocationError] = useState(false);
	const [currentCtcError, setCurrentCtcError] = useState(false);

	// const [selectedCountry, setSelectedCountry] = useState<{
	// 	name: string;
	// 	countryCode: string;
	// 	phoneCode: string;
	// }>()
	// const [selectedState, setSelectedState] = useState(null)


	const setStates = () => {
		setFirstName(data.user.firstname);
		setMiddleName(data.user.middlename);
		setLastName(data.user.lastname);
		setPosition({label: data.position?.label, value: data.position?.value, id: data.positionId});
		setExperience({label: data.experience, value: data.experience});
		setPhone(data.user.phone.value);
		setIndustries(data.industries);
		setAvatar(data.user.avatar ? data.user.avatar : userAvatar);
		setPersonalEmail(data.user.email);
		setLocation({label:`${data.location?.city ? data.location?.city + "," : ""} ${data.location?.state || ""}`, value: data.locationId});
		setCurrentCtc(data.currentCtc);
		setCurrency(data.currency);
		setHeadlines(data.headlines);
		setSummary(data.summary);
		setEducation({value: data.degree, label: data.degree});
	};
	useEffect(() => {
		if (data) {
			setStates();
			const isEditMode = data.user.phone.isResumeParsed;
			setEditMode(editMode ? editMode : isEditMode)
			setDisplayButton(editMode? editMode : isEditMode)
		}
	}, [data]);

	const onFormSubmit = async (e) => {
		e.preventDefault();
		//check style validate when onSubmit click
		firstName.length === 0 && (setFirstNameError(true));
		lastName.length === 0 && (setLastNameError(true));
		location?.value || (setLocationError(true));
		currentCtc === null  && (setCurrentCtcError(true));
		(currentCtc !== null && currency==="" || currentCtc && currency===null)  && (setCurrentCtcError(true));
		!validatePhoneNumber(phone) && phone.length <= phoneValidationLength ? setPhoneError(true) : setPhoneError(false);

		// check validate when onSubmit click
		if (formValidate()) {
			//need to write send logic
			const data = {
				phone: phone,
				firstname: firstName,
				middlename: middleName,
				lastname: lastName,
			};
			const otherData = {
				currentCtc: currentCtc,
				currency: currency,
				headlines: headlines,
				summary: summary,
				locationId: location.value,
				industries: industries,
				positionId: position.id,
				experience: experience.label,
				degree: education.value,
			};

			const user_res = await patchData(`${API_RECRUITMENT}/api/user`, data);
			const other_res = await patchData(`${API_RECRUITMENT}/api/candidate`, otherData);

			setRefetch(new Date());
			other_res.status === 200 && user_res.status === 200 && setSuccess(true);
			setEditMode(false)

			setTimeout(() => {
				setSuccess(false);
			}, 2000);
		}
	};

	const formValidate = () => {
		if ((currentCtc === null || currentCtc === "") && (currency === null || currency === "")) {
			setCurrentCtcError(true);
			return;
		  }
	  
		  if (currentCtc !== null && (currency === "" || currency === null || currency===undefined )) {
			setCurrentCtcError(true);
			return;
		  }
		return firstName.length !== 0  && lastName.length !== 0 && validatePhoneNumber(phone) && location?.value ;
	};

	const onClearHandler = (e) => {
		e.preventDefault();
		setEditMode(false);
		setDisplayButton(false);

		//added default data (need to write axios for get user data)
		setData(oldData => {
			const _oldData = { ...oldData }
			const _user = _oldData.user
			_user.phone.isResumeParsed = false
			_user.phone.value = _user.phone.oldValue
			_oldData.user = _user;

			return { ..._oldData }
		})

		//clear error style
		setFirstNameError(false);
		setMiddleNameError(false);
		setLastNameError(false);
		setPhoneError(false);
		setCurrentCtcError(false);
	};

	const onFormChange = () => {
		setDisplayButton(true);
	};

	const [hasMorePosition, setHasMorePosition] = useState(false);
	const [offsetPosition, setOffsetPosition] = useState(30);
	const [searchValuePosition, setSearchValuePosition] = useState(null);
	const [optionsPosition, setOptionsPosition] = useState([]);

	const loadOptionsPosition = async (inputValue) => {
		setSearchValuePosition(inputValue)
		if (hasMorePosition) {
			try {
				const response = await selectSearchFuncPositionLimit(`find=${inputValue && inputValue}&offset=${offsetPosition}`);
				if (response.length > 0) {
					const newOptions = response.map((item) => ({ value: item.id, label: item.label }));
					setOffsetPosition(offsetPosition + 30);
					const data = [...optionsPosition, ...newOptions];
					setOptionsPosition(data)
					setHasMorePosition(false);
					return data
				} else {
					setHasMorePosition(false);
					return optionsPosition
				}
			} catch (error) {
				console.error('Error loading more options:', error);
			}
		} else {
			const data = await selectSearchFuncPositionLimit(`find=${inputValue && inputValue}`);
			setOptionsPosition(data);
			return (data);
		}
	};

	useEffect(() => {
		if (hasMorePosition || optionsPosition?.length === 0) {
			loadOptionsPosition(searchValuePosition !== null ? searchValuePosition : "");
		}
	}, [hasMorePosition, optionsPosition?.length === 0]);

	const [hasMoreLocation, setHasMoreLocation] = useState(false);
	const [offsetLocation, setOffsetLocation] = useState(30);
	const [searchValueLocation, setSearchValueLocation] = useState(null);
	const [optionsLocation, setOptionsLocation] = useState([]);

	const loadOptionsLocation = async (inputValue) => {
		setSearchValueLocation(inputValue)
		if (hasMoreLocation) {
			try {
				const response = await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}&offset=${offsetLocation}`);
				if (response.length > 0) {
					const newOptions = response.map((item) => ({ value: item.id, label: item.label }));
					setOffsetLocation(offsetLocation + 30);
					const data = [...optionsLocation, ...newOptions];
					setOptionsLocation(data)
					setHasMoreLocation(false);
					return data
				} else {
					setHasMoreLocation(false);
					return optionsLocation
				}
			} catch (error) {
				console.error('Error loading more options:', error);
			}
		} else {
			const data = await selectSearchFuncLocationLimit(`find=${inputValue && inputValue}`);
			setOptionsLocation(data);
			return (data);
		}
	};

	useEffect(() => {
		if (hasMoreLocation || optionsLocation?.length === 0) {
			loadOptionsLocation(searchValueLocation !== null ? searchValueLocation : "");
		}
	}, [hasMoreLocation, optionsLocation?.length === 0]);
		
	return (
		<div className="profile__right__inner">
			<div className="profile__head">
				<div className="profile__head__inner">
					<p className="profile__head__title">
						Quick View {editMode && (<span> - Editing Mode</span>)}
					</p>
					{
						!editMode && (
							<p className="profile__head__edit" onClick={() => setEditMode(true)}>
								<span>Edit</span>
								<img src={editIc} alt="edit icon"/>
							</p>
						)
					}
				</div>
			</div>
			<form
				className={`profile__form ${editMode ? "" : "readonly"}`}
				onSubmit={(e) => onFormSubmit(e)}
				onChange={() => onFormChange()}
			>
				<div className="profile__form__group">
					<div
						className={`profile__form__avatar ${!editMode ? "default" : ""}`}
						onClick={() => {
							setEditStatusForPopup(editMode);
							setDisplayUploadPopup(true);
						}}
					>
						<div className="profile__form__animation">
							<img src={cameraIc} alt="icon" className="profile__form__animation--icon"/>
						</div>
						<img src={avatar} alt="user avatar" className="profile__form__avatar--image"/>
					</div>
					<div className="profile__form__group wrapped">
						<div className={`profile__form__item short ${firstNameError ? "error" : ""}`}>
							<label className="profile__form__label">First Name{editMode && <span> *</span>}</label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter first name" : "Not indicated"}`}
								value={firstName ? firstName : ""}
								readOnly={!editMode}
								onChange={(e) => {
									setFirstName(e.target.value);
									e.target.value.length <= 0 ? setFirstNameError(true) : setFirstNameError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item short`}>
							<label className="profile__form__label">Middle Name</label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter middle name" : "Not indicated"}`}
								value={middleName ? middleName : ""}
								readOnly={!editMode}
								onChange={(e) => {
									setMiddleName(e.target.value);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item short ${lastNameError ? "error" : ""}`}>
							<label className="profile__form__label">Last Name{editMode && <span> *</span>}</label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter last name" : "Not indicated"}`}
								value={lastName ? lastName : ""}
								readOnly={!editMode}
								onChange={(e) => {
									setLastName(e.target.value);
									e.target.value.length <= 0 ? setLastNameError(true) : setLastNameError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item`}>
							<label className="profile__form__label">Position</label>
							<AsyncSelect
								cacheOptions
								loadOptions={loadOptionsPosition}
								defaultOptions={optionsPosition && optionsPosition}
								options={optionsPosition && optionsPosition}
								isLoading={hasMorePosition}
								isDisabled={!editMode}
								onBlur={()=>{
									setOffsetLocation(30)
									loadOptionsPosition("");
								}}
								value={position ? position : searchValuePosition!== null ? searchValuePosition : null}
								onChange={(option: any) => {
									setDisplayButton(true);
									setPosition(option);
								}}
								placeholder={`${editMode ? "Select your position" : "Not indicated"}`}
								styles={!editMode ? selectDisableStyle : selectCustomStyle}
								id="positionSelect"
								instanceId="positionSelect"
								onMenuScrollToBottom={(e)=>{								
									!hasMorePosition && setHasMorePosition(true)
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>

						<div className={`profile__form__item experience`}>
							<label className="profile__form__label">Total Experience</label>
							<ReactSelect
								options={totalExperienceOption}
								closeMenuOnSelect={true}
								hideSelectedOptions={false}
								onChange={(item: any) => {
									setExperience(item);
									setDisplayButton(true);
								}}
								isDisabled={!editMode}
								value={experience ? totalExperienceOption.find(x => parseInt(x.label) === parseInt(experience.label)) : null}
								placeholder={`${editMode ? "Select total experience" : "Not indicated"}`}
								styles={!editMode ? selectDisableStyle : selectCustomStyle}
								id="experienceSelect"
								instanceId="experienceSelect"
							/>
							<p className="error-message">This is required field</p>
						</div>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item long`}>
						<label className="profile__form__label">Industry(ies)</label>
						<AsyncSelect
							cacheOptions
							isMulti
							components={{
								Option,
							}}
							closeMenuOnSelect={industries.length > 4}
							loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncIndustry(inputValue) : selectSearchFuncIndustry("")
							}
							defaultOptions
							isDisabled={!editMode}
							value={industries}
							onChange={(option: any) => {
								setDisplayButton(true);
								setIndustries(option);
							}}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							placeholder={`${editMode ? "Select Industry(ies)" : "Not indicated"}`}
							id="industrySelect"
							instanceId="industrySelect"
						/>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${phoneError ? "error" : ""}`}>
						<label className="profile__form__label">
							<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
								<Box>Phone{editMode && <span> *</span>}</Box>
								{data?.user?.phone?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
							</Stack>
						</label>
						<PhoneInput
							international
							defaultCountry="US"
							value={phone}
							disabled={!editMode}
							placeholder={`${editMode ? "" : "Not indicated"}`}
							className={!editMode ? "default" : ""}
							onChange={(value: any) => {
								if (value) {
									setPhone(value);
									!validatePhoneNumber(value) || value.length > phoneValidationLength ? setPhoneError(true) : setPhoneError(false);
								} else {
									setPhoneError(true);
								}
							}}
						/>
						<p className="error-message">
							Phone number not correct
						</p>
					</div>
					<div className={`profile__form__item`}>
						<label className="profile__form__label">Personal Email</label>
						<input
							type="email"
							className="profile__form__input readonly"
							placeholder={`${editMode ? "" : "Not indicated"}`}
							value={personalEmail ? personalEmail : ""}
							readOnly
						/>
						<p className="error-message">Please enter a valid Email address correct</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${locationError ? 'error' : ''}`}>
						<label className="profile__form__label">Location{editMode && <span> *</span>}</label>
						{/* <SelectLocationComponent
					// {...(jobLocationValidate && { error: { className: "error-message", message: "Required field" } })}
					fields={[
						{
							type: 'country',
							selectedValue: selectedCountry,
							setSelectedValue: setSelectedCountry,
							isDisabled:!editMode,
							value: selectedCountry || '',
						},
						{
							type: 'state',
							isDisabled:!editMode,
							selectedValue: selectedState,
							setSelectedValue: setSelectedState,
							value: selectedState || '',
						}
					]}
				/>  */}
						<AsyncSelect
							cacheOptions
							loadOptions={loadOptionsLocation}
							defaultOptions={optionsLocation && optionsLocation}
							options={optionsLocation && optionsLocation}
							isLoading={hasMoreLocation}
							isDisabled={!editMode}
							onBlur={() => {
								setOffsetLocation(30);
								loadOptionsLocation("")
							}}
							value={location ? location : searchValueLocation !== null ? searchValueLocation : null}
							onChange={(option: any) => {
								setDisplayButton(true);
								setLocation(option);
							}}
							placeholder={`${editMode ? "Select your Location" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
							onMenuScrollToBottom={(e) => {
								!hasMoreLocation && setHasMoreLocation(true)
							}}
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
					<div className={`profile__form__item ${currentCtcError ? 'error' : ''}`}>
						<label className="profile__form__label">Current compensation (Per Year){editMode && <span> *</span>}</label>
						<div className="ctc">
						<input
							type="number"
							className="profile__form__input number ctc__input"
							placeholder={`${editMode ? "Enter current compensation" : "Not indicated"}`}
							value={currentCtc ? currentCtc : ""}
							readOnly={!editMode}
							onChange={(e) => {
								e.target.value==="" && setCurrency("");
								setCurrentCtc(e.target.value);
								e.target.value.length <= 0 && (currency===undefined || currency===null || currency === "") ?   setCurrentCtcError(true)  : setCurrentCtcError(false);
							}}
						/>
						<ReactSelect
								className={`ctc__currency  ${currentCtcError ? 'error' : ''}`}
								options={currencyData}
								isClearable
								closeMenuOnSelect={true}
								hideSelectedOptions={false}
								onChange={(item: any) => {																		
									setCurrency(item?.value);
									setDisplayButton(true);
									item === null ?  setCurrentCtcError(true) : setCurrentCtcError(false);
								}}
								isDisabled={!editMode}
								value={currency ? currencyData.find(x => (x.value) === (currency)) : null}
								placeholder={`${editMode ? "Currency" : "Not indicated"}`}
								styles={!editMode ? selectDisableStyle : selectCustomStyle}
								id="currencySelect"
								instanceId="currencySelect"
							/>
						</div>
						<p className="error-message">
							Current compensation and Currency fields are required field
						</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className="profile__form__item">
						<div className="custom-checkbox salary">
							<input
								type="checkbox"
								className="custom-checkbox__input"
								id="remote"
								name="remote"
								checked={remoteLocationCheckbox}
								disabled={!editMode}
								onChange={() => setRemoteLocationCheckbox((prev) => !prev)}
							/>
							<label htmlFor="remote" className="custom-checkbox__label">
								<div className="custom-checkbox__item">
									<span></span>
								</div>
								<p className="custom-checkbox__text">Remote location</p>
							</label>
						</div>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item long `}>
						<p className="profile__form__label">Education </p>
						<ReactSelect
							options={educationOption}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setEducation(currentValue);
								setDisplayButton(true);
							}}
							isDisabled={!editMode}
							value={educationOption.find(x => x.label === education.label) ? educationOption.find(x => x.label === education.label) : ""}
							placeholder={`Select education`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="education"
							instanceId="education"
						/>
						<p className="error-message">
							Required field
						</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className="profile__form__item long">
						<label className="profile__form__label">Headlines & Summary</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter headlines" : "Not indicated"}`}
							value={headlines ? headlines : ""}
							readOnly={!editMode}
							onChange={(e) => {
								setHeadlines(e.target.value);
							}}
						/>
						<textarea
							className="profile__form__textarea"
							placeholder={`${editMode ? "Enter summary" : "Not indicated"}`}
							value={summary ? summary : ""}
							readOnly={!editMode}
							onChange={(e) => {
								setSummary(e.target.value);
							}}
						/>
					</div>
				</div>
				{
					editMode && displayButton ?
						<div className="profile__form__group">
							<div className="profile__form__item buttons">
								<button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
								</button>
								<button className="profile__form__submit button--filled" type="submit">Save</button>
							</div>
							{success ? <p className="success-message">All changes made</p> : null}
						</div>
						: null
				}
			</form>
		</div>
	);
};

export default memo(QuickViewTab);

