import PhoneInput from 'react-phone-number-input'
import DatePicker from 'react-datepicker'
import { default as ReactSelect } from 'react-select'
import { selectCustomStyle } from '../../styles/selectCustomStyle'
import { selectDisableStyle } from '../../styles/selectDisableStyle'
import { components } from 'react-select'
import { memo, useEffect, useState } from 'react'
import { getEnv } from "@urecruits/api"
import patchData from "../../hook/patchData"
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle"

const facebookIc = require('../../image/icon/facebook_ic.svg')
const instagramIc = require('../../image/icon/instagram_ic.svg')
const linkedinIc = require('../../image/icon/linkedIn_ic.svg')
const twitterIc = require('../../image/icon/twitter_ic.svg')
const editIc = require('../../image/icon/edit_ic.svg')

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="profile__form__checkbox">
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label htmlFor={props.value}><span/></label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  )
}

const genderOption = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
]

const materialOption = [
  { value: 'Married', label: 'Married' },
  { value: 'Single', label: 'Single' },
  { value: 'Divorced', label: 'Divorced' },
]

const PersonalDetailsTab = ({ data, setRefetch, usState, countryList, languagesList }) => {
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)

  const [birthday, setBirthday] = useState(null)
  const [gender, setGender] = useState('')
  const [maritalStatus, setMaritalStatus] = useState('')
  const [emergencyContactPerson, setEmergencyContactPerson] = useState('')
  const [relationWithEmployee, setRelationWithEmployee] = useState('')
  const [emergencyContactMobile, setEmergencyContactMobile] = useState(null)
  const [personalEmailId, setPersonalEmailId] = useState('')
  const [languagesKnown, setLanguagesKnown] = useState([])

  //social media links
  const [linkedin, setLinkedin] = useState('')
  const [facebook, setFacebook] = useState('')
  const [twitter, setTwitter] = useState('')
  const [instagram, setInstagram] = useState('')

  //current address
  const [currentStreet, setCurrentStreet] = useState('')
  const [currentHouseNameOrNumber, setCurrentHouseNameOrNumber] = useState('')
  const [currentCity, setCurrentCity] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')
  const [currentState, setCurrentState] = useState('')
  const [currentZipPostcode, setCurrentZipPostcode] = useState('')
  //permanent address
  const [permanentStreet, setPermanentStreet] = useState('')
  const [permanentHouseNameOrNumber, setPermanentHouseNameOrNumber] = useState('')
  const [permanentCity, setPermanentCity] = useState('')
  const [permanentCountry, setPermanentCountry] = useState('')
  const [permanentState, setPermanentState] = useState('')
  const [permanentZipPostcode, setPermanentZipPostcode] = useState('')

  //error
  const [emergencyContactMobileError, setEmergencyContactMobileError] = useState(false)
  const setStates = () => {
    setBirthday(new Date(data.birthday))
    setGender(data.gender)
    setMaritalStatus(data.maritalStatus)
    setEmergencyContactPerson(data.emergencyPerson)
    setRelationWithEmployee(data.relationEmployee)
    setEmergencyContactMobile(data.emergencyMobile)
    setPersonalEmailId(data.user.email)
    setLanguagesKnown(data.languages ? JSON.parse(data.languages) : '')
    setLinkedin(data.linkedin)
    setFacebook(data.facebook)
    setTwitter(data.twitter)
    setInstagram(data.instagram)
    setCurrentCity(data.currentCity)
    setCurrentCountry(data.currentCountry)
    setCurrentHouseNameOrNumber(data.currentHouseNumber)
    setCurrentState(data.currentState)
    setCurrentStreet(data.currentStreet)
    setCurrentZipPostcode(data.currentZip)
    setPermanentCity(data.permanentCity)
    setPermanentCountry(data.permanentCountry)
    setPermanentHouseNameOrNumber(data.permanentHouseNumber)
    setPermanentState(data.permanentState)
    setPermanentStreet(data.permanentStreet)
    setPermanentZipPostcode(data.permanentZip)
  }
  useEffect(() => {
    if (data) {
      setStates()
    }
  }, [data])

  const onFormSubmit = async (e) => {
    e.preventDefault()
    !emergencyContactMobile || emergencyContactMobile.length > 14 && (setEmergencyContactMobileError(true))

    if (!emergencyContactMobile || emergencyContactMobile.length < 14) {
      const tmpData = {
        birthday: birthday,
        gender: gender,
        maritalStatus: maritalStatus,
        emergencyPerson: emergencyContactPerson,
        relationEmployee: relationWithEmployee,
        emergencyMobile: emergencyContactMobile,
        personalEmail: personalEmailId,
        languages: JSON.stringify(languagesKnown),
        linkedin: linkedin,
        facebook: facebook,
        twitter: twitter,
        instagram: instagram,
        currentCity: currentCity,
        currentCountry: currentCountry,
        currentHouseNumber: currentHouseNameOrNumber,
        currentState: currentState,
        currentStreet: currentStreet,
        currentZip: currentZipPostcode,
        permanentCity: permanentCity,
        permanentCountry: permanentCountry,
        permanentHouseNumber: permanentHouseNameOrNumber,
        permanentState: permanentState,
        permanentStreet: permanentStreet,
        permanentZip: permanentZipPostcode,
      }

      await patchData(`${API_RECRUITMENT}/api/recruiter`, tmpData).then((res) => {
        setRefetch(new Date())
        res.status === 200 ? setSuccess(true) : setSuccess(false)
      })

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }
  const {API_RECRUITMENT} = getEnv()

  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    setStates()
  }

  return (
		<AuthGuard module='recruiter'>
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Personal Details {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        <div className="profile__form__part">
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">Birthday</label>
              <div className="profile__form__date">
                <DatePicker
                  selected={birthday}
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  onChange={(date: any) => {
                    setBirthday(date)
                    setDisplayButton(true)
                  }}
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  readOnly={!editMode}
                  placeholderText="Enter date of birth"
                />
              </div>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">Gender</label>
              <ReactSelect
                options={genderOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setGender(item.value)
                  setDisplayButton(true)
                }}
                value={genderOption.find(x => x.value === gender) ? genderOption.find(x => x.value === gender) : ''}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Select gender' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="genderSelect"
                instanceId="genderSelect"
              />

            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">Marital Status</label>
              <ReactSelect
                options={materialOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setMaritalStatus(item.value)
                  setDisplayButton(true)
                }}
                value={materialOption.find(x => x.value === maritalStatus) ? materialOption.find(x => x.value === maritalStatus) : ''}
                placeholder={`${editMode ? 'Select your marital status' : 'Not indicated'}`}
                isDisabled={!editMode}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="maritalStatus"
                instanceId="maritalStatus"
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">Emergency Contact Person</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter your emergency contact person' : 'Not indicated'}`}
                readOnly={!editMode}
                value={emergencyContactPerson ? emergencyContactPerson : ''}
                onChange={(e) => setEmergencyContactPerson(e.target.value)}
              />
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">Relation with Employee</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter your relation with employee' : 'Not indicated'}`}
                readOnly={!editMode}
                value={relationWithEmployee ? relationWithEmployee : ''}
                onChange={(e) => setRelationWithEmployee(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item ${emergencyContactMobileError ? 'error' : ''}`}>
              <label className="profile__form__label">Emergency Contact Mobile</label>
              <PhoneInput
                international
                defaultCountry="US"
                value={emergencyContactMobile ? emergencyContactMobile : null}
                disabled={!editMode}
                className={!editMode ? 'default': ''}
                onChange={(value: any) => {
                  setDisplayButton(true)
                  if (value) {
                    setEmergencyContactMobile(value)
                    value.length > 13 ? setEmergencyContactMobileError(true) : setEmergencyContactMobileError(false)
                  } else {
                    setEmergencyContactMobile(null)
                    setEmergencyContactMobileError(false)
                  }
                }}
                placeholder={`${editMode ? '' : 'Not indicated'}`}
              />
              <p className="error-message">
                Phone number not correct
              </p>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">Personal Email</label>
              <input
                type="email"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter your personal email' : 'Not indicated'}`}
                value={personalEmailId ? personalEmailId : ''}
                readOnly={!editMode}
                onChange={(e) => setPersonalEmailId(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item long">
              <label className="profile__form__label">Languages Known</label>
              <ReactSelect
                options={languagesList}
                closeMenuOnSelect={false}
                isMulti
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setLanguagesKnown(item)
                  setDisplayButton(true)
                }}
                value={languagesKnown}
                isDisabled={!editMode}
                components={{
                  Option,
                }}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="languagesSelect"
                instanceId="languagesSelect"
                placeholder={`${editMode ? 'Select...' : 'Not indicated'}`}
              />
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Social Media Links
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">Linkedin</label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={linkedinIc} alt="social icon"/>
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Linkedin profile' : 'Not indicated'}`}
                  value={linkedin ? linkedin : ''}
                  readOnly={!editMode}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </div>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">Facebook</label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={facebookIc} alt="social icon"/>
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Facebook profile' : 'Not indicated'}`}
                  value={facebook ? facebook : ''}
                  readOnly={!editMode}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">Twitter</label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={twitterIc} alt="social icon"/>
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Twitter profile' : 'Not indicated'}`}
                  value={twitter ? twitter : ''}
                  readOnly={!editMode}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">Instagram</label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={instagramIc} alt="social icon"/>
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Instagram profile' : 'Not indicated'}`}
                  value={instagram ? instagram : ''}
                  readOnly={!editMode}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Current Address
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Street</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123 Main Street' : 'Not indicated'}`}
                value={currentStreet ? currentStreet : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentStreet(e.target.value)}
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">House name or number</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: Flat #3A' : 'Not indicated'}`}
                value={currentHouseNameOrNumber ? currentHouseNameOrNumber : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentHouseNameOrNumber(e.target.value)}
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">City</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter city' : 'Not indicated'}`}
                value={currentCity ? currentCity : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentCity(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Country</label>
              <ReactSelect
                options={countryList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setCurrentCountry(item.label)
                  setDisplayButton(true)
                }}
                value={currentCountry?countryList.find(x => x.label === currentCountry):null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Enter country' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="countrySelect"
                instanceId="countrySelect"
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">State</label>
              <ReactSelect
                options={usState}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setCurrentState(item.label)
                  setDisplayButton(true)
                }}
                value={currentState?usState.find(x => x.label === currentState):null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Select state' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="currentStateSelect"
                instanceId="currentStateSelect"
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">Zip/Postcode</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123456' : 'Not indicated'}`}
                value={currentZipPostcode ? currentZipPostcode : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentZipPostcode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Permanent Address
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Street</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123 Main Street' : 'Not indicated'}`}
                value={permanentStreet ? permanentStreet : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentStreet(e.target.value)}
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">House name or number</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: Flat #3A' : 'Not indicated'}`}
                value={permanentHouseNameOrNumber ? permanentHouseNameOrNumber : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentHouseNameOrNumber(e.target.value)}
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">City</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter city' : 'Not indicated'}`}
                value={permanentCity ? permanentCity : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentCity(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Country</label>
              <ReactSelect
                options={countryList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setPermanentCountry(item.label)
                  setDisplayButton(true)
                }}
                value={permanentCountry?countryList.find(x => x.label === permanentCountry):null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Enter country' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="countryPermanentSelect"
                instanceId="countryPermanentSelect"
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">State</label>
              <ReactSelect
                options={usState}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setPermanentState(item.label)
                  setDisplayButton(true)
                }}
                isDisabled={!editMode}
                value={permanentState?usState.find(x => x.label === permanentState):null}
                placeholder={`${editMode ? 'Select state' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="currentStateSelect"
                instanceId="currentStateSelect"
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">Zip/Postcode</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123456' : 'Not indicated'}`}
                value={permanentZipPostcode ? permanentZipPostcode : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentZipPostcode(e.target.value)}
              />
            </div>
          </div>
        </div>
        {
          displayButton && editMode ?
            <div className="profile__form__part last">
              <div className="profile__form__group">
                <div className="profile__form__item buttons">
                  <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
                  <button className="profile__form__submit button--filled" type="submit">Save</button>
                </div>
                {success ? <p className="success-message">All changes made</p> : null}
              </div>
            </div>
            : null
        }
      </form>
    </div>
    </AuthGuard>
  )
}

export default memo(PersonalDetailsTab)