import DatePicker from 'react-datepicker'
import { default as ReactSelect } from 'react-select'
import { selectCustomStyle } from '../../styles/selectCustomStyle'
import { selectDisableStyle } from '../../styles/selectDisableStyle'
import { components } from 'react-select'
import { memo, useEffect, useState } from 'react'
import axios from 'axios'
import SmallLoader from '../SmallLoader'
import ProfileNotification from '../ProfileNotification'
import { validateUrl } from '../../hook/validateUrl'
import patchData from "../../hook/patchData"
import { getEnv } from "@urecruits/api"
import { Stack, Typography } from '@mui/material'

const facebookIc = require('../../image/icon/facebook_ic.svg')
const instagramIc = require('../../image/icon/instagram_ic.svg')
const linkedinIc = require('../../image/icon/linkedIn_ic.svg')
const twitterIc = require('../../image/icon/twitter_ic.svg')
const editIc = require('../../image/icon/edit_ic.svg')
const paperClipIc = require('../../image/icon/paper_clip_ic.svg')
const downloadIc = require('../../image/icon/download_ic.svg')
const removeIc = require('../../image/icon/delete_ic.svg')
const copyLink = require('../../image/icon/copy-link_ic.svg')


const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="profile__form__checkbox">
        <input
          id={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label htmlFor={props.value}><span /></label>
        <p>{props.label}</p>
      </components.Option>
    </div>
  )
}

const genderOption = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

const martialOption = [
  { value: 'Married', label: 'Married' },
  { value: 'Single', label: 'Single' },
  { value: 'Divorced', label: 'Divorced' },
]

function formatDate(inputDate) {
  // Parse the input date
  let date = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    // If not a valid date, return the current date
    date = new Date();
  }

  // Extract year, month, and pad the month if necessary
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0');

  // Return the formatted date
  return `${year}-${month}-01T00:00:00.000Z`;
}

const PersonalDetailsTabAndCv = ({ usState, countryList, languagesList, data, setData, setRefetch, setResumeParsingData, resumeParsingData }) => {
  const token: string = localStorage.getItem('token')

  const [loader, setLoader] = useState(false)
  const [success, setSuccess] = useState(false)
  const [displayUploadInfo, setDisplayUploadInfo] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [displayCopied, setDisplayCopied] = useState(false)
  const [urlLoader, setUrlLoader] = useState(false)
  const [url, setUrl] = useState('')

  const [cv, setCv] = useState('')
  const [birthday, setBirthday] = useState(null)
  const [gender, setGender] = useState('')
  const [materialStatus, setMaterialStatus] = useState('')
  const [languagesKnown, setLanguagesKnown] = useState('')
  const [onlineProfile, setOnlineProfile] = useState('')
  const [cvName, setCvName] = useState('')

  //social media links
  const [linkedin, setLinkedin] = useState('')
  const [facebook, setFacebook] = useState('')
  const [twitter, setTwitter] = useState('')
  const [instagram, setInstagram] = useState('')

  //current address
  const [currentStreet, setCurrentStreet] = useState('')
  const [currentHouseNameOrNumber, setCurrentHouseNameOrNumber] = useState('')
  const [currentCity, setCurrentCity] = useState('')
  const [currentCountry, setCurrentCountry] = useState('')
  const [currentState, setCurrentState] = useState('')
  const [currentZipPostcode, setCurrentZipPostcode] = useState('')

  //permanent address
  const [permanentStreet, setPermanentStreet] = useState('')
  const [permanentHouseNameOrNumber, setPermanentHouseNameOrNumber] = useState('')
  const [permanentCity, setPermanentCity] = useState('')
  const [permanentCountry, setPermanentCountry] = useState('')
  const [permanentState, setPermanentState] = useState('')
  const [permanentZipPostcode, setPermanentZipPostcode] = useState('')

  //error
  const [birthdayError, setBirthdayError] = useState(false)
  const [genderError, setGenderError] = useState(false)

  const [fileTypeError, setFileTypeError] = useState(false)
  const [fileSizeError, setFileSizeError] = useState(false)
  const [onlineProfileError, setOnlineProfileError] = useState(false)
  const [loading, setLoading] = useState(false)

  const setStates = () => {
    setBirthday(data.birthday?.value ? new Date(data.birthday?.value) : null)
    setGender(data.gender.value)
    setMaterialStatus(data.maritalStatus.value)
    setLinkedin(data.linkedin.value)
    setFacebook(data.facebook.value)
    setTwitter(data.twitter.value)
    setInstagram(data.instagram.value)
    setCurrentCity(data.currentCity.value)
    setCurrentStreet(data.currentStreet.value)
    setCurrentHouseNameOrNumber(data.currentHouseNumber.value)
    setCurrentCountry(data.currentCountry.value)
    setCurrentState(data.currentState.value)
    setCurrentZipPostcode(data.currentZip.value)
    setPermanentStreet(data.permanentStreet)
    setPermanentHouseNameOrNumber(data.permanentHouseNumber)
    setPermanentCity(data.permanentCity)
    setPermanentCountry(data.permanentCountry)
    setPermanentState(data.permanentState)
    setPermanentZipPostcode(data.permanentZip)
    setOnlineProfile(data.onlineProfile)
    setLanguagesKnown(data.languages ? JSON.parse(data.languages) : '')
    setCv(data.cvKey.value)
    setCvName(data.cvName.value)

    if (data.cvName.value && data.cvKey.value) {
      setDisplayUploadInfo(true)
    } else {
      setDisplayUploadInfo(false)
    }
  }
  const { API_RECRUITMENT } = getEnv()

  useEffect(() => {
    if (data) {
      setStates()
      const isEditMode = data.gender.isResumeParsed || data.maritalStatus.isResumeParsed || data.instagram.isResumeParsed || data.twitter.isResumeParsed || data.facebook.isResumeParsed || data.linkedin.isResumeParsed;
      setEditMode(editMode ? editMode : isEditMode)
      setDisplayButton(editMode ? editMode : isEditMode)
    }
  }, [data])
  const onFormSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    !gender ? setGenderError(true) : setGenderError(false)
    !birthday ? setBirthdayError(true) : setBirthdayError(false)
    onlineProfile && (validateUrl(onlineProfile) ? setOnlineProfileError(false) : setOnlineProfileError(true))

    if (birthday && gender && !onlineProfileError) {
      //need to write send logic
      const data = {
        birthday: birthday,
        gender: gender,
        maritalStatus: materialStatus,
        linkedin: linkedin,
        facebook: facebook,
        twitter: twitter,
        instagram: instagram,
        currentStreet: currentStreet,
        currentHouseNumber: currentHouseNameOrNumber,
        currentCity: currentCity,
        currentCountry: currentCountry,
        languages: JSON.stringify(languagesKnown),
        currentState: currentState,
        currentZip: currentZipPostcode,
        permanentStreet: permanentStreet,
        permanentHouseNumber: permanentHouseNameOrNumber,
        permanentCity: permanentCity,
        permanentCountry: permanentCountry,
        permanentState: permanentState,
        permanentZip: permanentZipPostcode,
        onlineProfile: onlineProfile,
        cvName: cvName,
        cvKey: cv,
      }

      try{
      const res = await patchData(`${API_RECRUITMENT}/api/candidate`, data)
      if (res.status === 200) {
        setSuccess(true)
        setData(oldData => {
          const _oldData = JSON.parse(JSON.stringify(oldData))

          const fields = [
            { key: "birthday", value: birthday },
            { key: "gender", value: gender },
            { key: "maritalStatus", value: materialStatus },
            { key: "instagram", value: instagram },
            { key: "twitter", value: twitter },
            { key: "facebook", value: facebook },
            { key: "linkedin", value: linkedin },
            { key: "currentStreet", value: currentStreet },
            { key: "currentHouseNumber", value: currentHouseNameOrNumber },
            { key: "currentCity", value: currentCity },
            { key: "currentCountry", value: currentCountry },
            { key: "currentState", value: currentState },
            { key: "currentZip", value: currentZipPostcode },
            { key: "cvName", value: res.data.cvName },
            { key: "cvKey", value: res.data.cvKey },
          ];
          fields.forEach(item => {
            _oldData[item.key].isResumeParsed = false
            _oldData[item.key].value = item.value
          })
          
          return { ..._oldData }
        })
        setLoading(false)
        setEditMode(false)
        setDisplayButton(false)
        setUrl("");
      }
    }catch(error){
      console.log(error)
      setLoading(false)
    }
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    } else {
      setLoading(false)
    }
  }

  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    setData(oldData => {
      const _oldData = { ...oldData }
      const fields = ["birthday", "gender", "maritalStatus", "instagram", "twitter", "facebook", "linkedin", "currentStreet", "currentHouseNumber", "currentCity", "currentCountry", "currentState", "currentZip", "cvKey", "cvName"]

      fields.forEach(item => {
        _oldData[item].isResumeParsed = false
        _oldData[item].value = _oldData[item].oldValue
      })
      
      return { ..._oldData }
    })
    setStates()
  }

  const onRemoveHandler = () => {
    if (editMode) {
      setDisplayUploadInfo(false)
      setCv('')
      setCvName('')
      setUrl('')
      setDisplayButton(true)
      setData(oldData => {
        const data = { ...oldData };
        data.cvName.value = "";
        data.cvKey.value = "";
        return data
      })
    }
  }

  const fileValidate = (value) => {
    return value.size / 1024 < 5000 && (value.type === 'application/pdf' || value.type === 'image/jpeg' || value.type === 'image/png')
  }

  const onCopyHandler = () => {
    navigator.clipboard.writeText('www.urecruits.com/ur/' + onlineProfile)
    setDisplayCopied(true)
    setTimeout(() => {
      setDisplayCopied(false)
    }, 3000)
  }

  const handleNewFileUpload = async (e) => {
    //validate
    e.target.files[0].size / 1024 > 5000 ? setFileSizeError(true) : setFileSizeError(false)
    e.target.files[0].type === 'application/pdf' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png'
      ? setFileTypeError(false)
      : setFileTypeError(true)

    if (fileValidate(e.target.files[0])) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('upload', file)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      }

      setLoader(true)
      try {
        

      const apiData = await axios.post(`${API_RECRUITMENT}/api/candidate/resume`, formData, config)
        const { data, status } = apiData;
        if (status === 201) {
          if (data.text) {
            const resumeParsedDataWithIds: any = {};
            Object.entries(data.text).forEach(([key, value]) => {
              if (Array.isArray(value)) {
                resumeParsedDataWithIds[key] = addUniqueIdToObjectArray(value);
              } else {
                resumeParsedDataWithIds[key] = value;
              }
            });
            const resumeParsedSocial = resumeParsedDataWithIds?.["social_media"][0];
            const resumeParsedSkills = resumeParsedDataWithIds?.skills ? resumeParsedDataWithIds?.skills?.map(item => ({ id: item.id, name: item.skill_name, years: item.years_of_experience, isResumeParsed: true })) : [];
            const resumeParsedExperiences = resumeParsedDataWithIds?.experience_details ? resumeParsedDataWithIds?.experience_details?.map(item => ({ id: item.id, companyName: item.company_name, position: item.position, location: item.location, jobDescription: item.job_description, start: formatDate(item?.service_period?.start_date), end: formatDate(item?.service_period?.end_date), isResumeParsed: true })) : []
            const resumeParsedEducations = resumeParsedDataWithIds?.education_details ? resumeParsedDataWithIds?.education_details?.map(item => ({ id: item.id, universityName: item.university_institute_name, courseName: item.course_name, specification: item.specification, gpa: item.percentage, coursePeriodStart: formatDate(item?.course_period?.start_date), coursePeriodEnd: formatDate(item?.course_period?.end_date), isResumeParsed: true, })) : []
            const resumeParsedCertificates = resumeParsedDataWithIds?.certificates ? resumeParsedDataWithIds?.certificates?.map(item => ({ id: item.id, certificateName: item.certificate_name, instituteName: item.institute_name, validityStart: formatDate(item?.certificate_period?.start_date), validityEnd: formatDate(item?.certificate_period?.end_date), expireDate: formatDate(item?.expiring_date), isResumeParsed: true })) : []
            const resumeParsedAwards = resumeParsedDataWithIds?.awards ? resumeParsedDataWithIds?.awards?.map(item => ({ id: item.id, name: item.award_name, companyName: item.company_name, isResumeParsed: true })) : []
            const resumeParsedProjects = resumeParsedDataWithIds?.projects ? resumeParsedDataWithIds?.projects?.map(item => ({ id: item.id, projectName: item.project_name, isResumeParsed: true })) : []
            const resumeParsedCurrentAddress = resumeParsedDataWithIds?.current_address;

            setData((oldData) => ({
              ...oldData,
              skills: [...oldData?.skills, ...resumeParsedSkills],
              experiences: [...oldData?.experiences, ...resumeParsedExperiences],
              educations: [...oldData?.educations, ...resumeParsedEducations],
              certificates: [...oldData?.certificates, ...resumeParsedCertificates],
              awards: [...oldData?.awards, ...resumeParsedAwards],
              projects: [...oldData?.projects, ...resumeParsedProjects],
              gender: changeValueStructure(oldData.gender, resumeParsedDataWithIds?.gender),
              maritalStatus: changeValueStructure(oldData.maritalStatus, resumeParsedDataWithIds?.marital_status),
              linkedin: changeValueStructure(oldData.linkedin, resumeParsedSocial?.linked_in),
              twitter: changeValueStructure(oldData.twitter, resumeParsedSocial?.twitter),
              facebook: changeValueStructure(oldData.facebook, resumeParsedSocial?.facebook),
              instagram: changeValueStructure(oldData.instagram, resumeParsedSocial?.instagram),
              birthday: changeValueStructure(oldData.birthday, formatDate(resumeParsedSocial?.birthday)),
              currentStreet: changeValueStructure(oldData.currentStreet, resumeParsedCurrentAddress?.street),
              currentHouseNumber: changeValueStructure(oldData.currentHouseNumber, resumeParsedCurrentAddress?.house_no),
              currentCity: changeValueStructure(oldData.currentCity, resumeParsedCurrentAddress?.city),
              currentCountry: changeValueStructure(oldData.currentCountry, resumeParsedCurrentAddress?.country),
              currentState: changeValueStructure(oldData.currentState, resumeParsedCurrentAddress?.state),
              currentZip: changeValueStructure(oldData.currentZip, resumeParsedCurrentAddress?.zip_code),
              cvName: { ...oldData.cvName, value: file ? file.name : '' },
              cvKey: { ...oldData.cvName, value: data.fileS3Key },
              user: {
                ...oldData.user,
                phone: changeValueStructure(oldData.user.phone, resumeParsedDataWithIds.contact)
              }
            }))
            setCvName(file ? file.name : '')
            setCv(data.fileS3Key)
            setLoader(false)
            setDisplayUploadInfo(true)
          } else if (data.fileS3Key) {
            setData(oldData => ({
              ...oldData,
              cvName: { ...oldData.cvName, value: file ? file.name : '' },
              cvKey: { ...oldData.cvName, value: data.fileS3Key },
            }))
            setCvName(file ? file.name : '')
            setCv(data.fileS3Key)
            setLoader(false)
            setDisplayUploadInfo(true)
          }
        }
      } catch (error) {
        alert("Something went Wrong, please upload valid file and try again")
        setCvName('')
        setCv("")
        setLoader(false)
        setDisplayUploadInfo(false)
      }
    }
  }

  const onDownloadHandler = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
    const formData = {
      'key': cv,
    }
    setUrlLoader(true)
    const { data } = await axios.post(`${API_RECRUITMENT}/api/file/get-private`, formData, config)
    setUrl(data)
    setUrlLoader(false)
  }

  function addUniqueIdToObjectArray(objectArray) {
    return objectArray.map(obj => {
      return { ...obj, id: Math.random().toString(36).substr(2, 9) };
    });
  }

  const changeValueStructure = (oldData, parsedValue) => {
    return {
      ...oldData,
      value: oldData?.value || parsedValue,
      isResumeParsed: (!oldData?.value && parsedValue) && true
    }
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Personal Details & CV {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon" />
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        <div className="profile__form__part">
          <div className="profile__form__group">
            <div className="profile__form__item long">
              {
                displayUploadInfo ?
                  <div className={`profile__upload ${editMode ? '' : 'readonly'}`}>
                    <p className="profile__upload__type">
                      CV File
                    </p>
                    <div className="profile__upload__body">
                      <div className="profile__upload__left">
                        <div className="profile__upload__info">
                          <div className="profile__upload__button hidden">
                            <img src={paperClipIc} alt="upload icon" className="profile__upload__button--icon" />
                            <p className="profile__upload__button--name">
                              Please upload your CV
                            </p>
                          </div>
                          <div className="profile__upload__button">
                            <img src={paperClipIc} alt="upload icon" className="profile__upload__button--icon" />
                            <p className="profile__upload__button--name">
                              {cvName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="profile__upload__right">
                        {!url && (
                          <img src={downloadIc} alt="download icon" className="profile__upload__download"
                            onClick={onDownloadHandler} />
                        )}
                        {urlLoader && <p>Loading...</p>}
                        {url && <a href={url} target='_blank'>Download</a>}
                        {editMode && 
                        <img
                        src={removeIc}
                        alt="remove icon"
                        className="profile__upload__remove"
                        onClick={onRemoveHandler}
                      />
                        }
                      </div>
                    </div>
                  </div>
                  :
                  <div className="profile__file">
                    {loader && (<SmallLoader />)}
                    <div className="profile__file__headline">
                      CV
                    </div>
                    <input
                      type="file"
                      name="uploadCv"
                      id="uploadCv"
                      disabled={!editMode}
                      accept="application/pdf"
                      className="profile__file__input"
                      onChange={handleNewFileUpload}
                    />
                    <label
                      htmlFor="uploadCv"
                      className={`profile__file__button  ${editMode ? 'button--empty' : 'button--empty-disable'}`}
                    >
                      Upload file
                    </label>
                    <p className="error-message">
                      {fileSizeError ? 'File size must be less than 5 MB.' : ''} {fileTypeError ? ' Incorrect file type.' : ''}
                    </p>
                  </div>
              }
            </div>
          </div>
          <div className="profile__form__group">
            <div className={`profile__form__item ${birthdayError ? 'error' : ''}`}>
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Birthday<span> *</span></div>
                  {data?.birthday?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <div className="profile__form__date">
                <DatePicker
                  selected={birthday}
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={70}
                  onChange={(date: any) => {
                    setBirthday(date)
                    setDisplayButton(true)
                    !date ? setBirthdayError(true) : setBirthdayError(false)
                  }}
                  dateFormat="MMMM d, yyyy"
                  maxDate={new Date()}
                  readOnly={!editMode}
                  placeholderText="Enter date of birth"
                />
                <p className="error-message">This is required field</p>
              </div>
            </div>
            <div className={`profile__form__item ${genderError ? 'error' : ''}`}>
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Gender <span> * </span></div>
                  {data?.gender?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <ReactSelect
                options={genderOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setGender(item.label)
                  setDisplayButton(true)
                  !item ? setGenderError(true) : setGenderError(false)
                }}
                value={gender ? genderOption.find(x => x.label === gender) : null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Select gender' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="genderSelect"
                instanceId="genderSelect"
              />
              <p className="error-message">This is required field </p>
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  Marital Status
                  {data?.maritalStatus?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <ReactSelect
                options={martialOption}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setMaterialStatus(item.label)
                  setDisplayButton(true)
                }}
                value={materialStatus ? martialOption.find(x => x.label === materialStatus) : null}
                placeholder={`${editMode ? 'Select your marital status' : 'Not indicated'}`}
                isDisabled={!editMode}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="materialStatus"
                instanceId="materialStatus"
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item long">
              <label className="profile__form__label">Languages Known</label>
              <ReactSelect
                options={languagesList}
                closeMenuOnSelect={false}
                isMulti
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setLanguagesKnown(item)
                  setDisplayButton(true)
                }}
                value={languagesKnown}
                placeholder={`${editMode ? 'Select...' : 'Not indicated'}`}
                isDisabled={!editMode}
                components={{
                  Option,
                }}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="languagesSelect"
                instanceId="languagesSelect"
              />
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Social Media Links
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Linkedin</div>
                  {data?.linkedin?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={linkedinIc} alt="social icon" />
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Linkedin profile' : 'Not indicated'}`}
                  value={linkedin ? linkedin : ''}
                  readOnly={!editMode}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </div>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Facebook</div>
                  {data?.facebook?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={facebookIc} alt="social icon" />
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Facebook profile' : 'Not indicated'}`}
                  value={facebook ? facebook : ''}
                  readOnly={!editMode}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Twitter</div>
                  {data?.twitter?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={twitterIc} alt="social icon" />
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Twitter profile' : 'Not indicated'}`}
                  value={twitter ? twitter : ''}
                  readOnly={!editMode}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
            </div>
            <div className="profile__form__item">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Instagram</div>
                  {data?.instagram?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <div className="profile__form__social">
                <div className={`profile__form__social--icon ${!editMode ? 'default' : ''}`}>
                  <img src={instagramIc} alt="social icon" />
                </div>
                <input
                  type="url"
                  className="profile__form__social--input"
                  placeholder={`${editMode ? 'Enter link to your Instagram profile' : 'Not indicated'}`}
                  value={instagram ? instagram : ''}
                  readOnly={!editMode}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Current Address
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Street</div>
                  {data?.currentStreet?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123 Main Street' : 'Not indicated'}`}
                value={currentStreet ? currentStreet : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentStreet(e.target.value)}
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>House name or number</div>
                  {data?.currentHouseNumber?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: Flat #3A' : 'Not indicated'}`}
                value={currentHouseNameOrNumber ? currentHouseNameOrNumber : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentHouseNameOrNumber(e.target.value)}
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>City</div>
                  {data?.currentCity?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter city' : 'Not indicated'}`}
                value={currentCity ? currentCity : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentCity(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Country</div>
                  {data?.currentCountry?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <ReactSelect
                options={countryList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setCurrentCountry(item.label)
                  setDisplayButton(true)
                }}
                value={currentCountry ? countryList.find(x => x.label === currentCountry) : null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Enter country' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="countrySelect"
                instanceId="countrySelect"
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>State</div>
                  {data?.currentState?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <ReactSelect
                options={usState}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setCurrentState(item.label)
                  setDisplayButton(true)
                }}
                value={currentState ? usState.find(x => x.label === currentState) : null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Select state...' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="stateSelect"
                instanceId="stateSelect"
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">
                <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                  <div>Zip/Postcode</div>
                  {data?.currentZip?.isResumeParsed && <Typography variant='subtitle2' color={"red"}>(Unsaved)</Typography>}
                </Stack>
              </label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123456' : 'Not indicated'}`}
                value={currentZipPostcode ? currentZipPostcode : ''}
                readOnly={!editMode}
                onChange={(e) => setCurrentZipPostcode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <p className="profile__form__part--headline">
            Permanent Address
          </p>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Street</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123 Main Street' : 'Not indicated'}`}
                value={permanentStreet ? permanentStreet : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentStreet(e.target.value)}
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">House name or number</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: Flat #3A' : 'Not indicated'}`}
                value={permanentHouseNameOrNumber ? permanentHouseNameOrNumber : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentHouseNameOrNumber(e.target.value)}
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">City</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Enter city' : 'Not indicated'}`}
                value={permanentCity ? permanentCity : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentCity(e.target.value)}
              />
            </div>
          </div>
          <div className="profile__form__group">
            <div className="profile__form__item w40">
              <label className="profile__form__label">Country</label>
              <ReactSelect
                options={countryList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setPermanentCountry(item.label)
                  setDisplayButton(true)
                }}
                value={permanentCountry ? countryList.find(x => x.label === permanentCountry) : null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Enter country' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="countryPermanentSelect"
                instanceId="countryPermanentSelect"
              />
            </div>
            <div className="profile__form__item w35">
              <label className="profile__form__label">State</label>
              <ReactSelect
                options={usState}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={(item: any) => {
                  setPermanentState(item.label)
                  setDisplayButton(true)
                }}
                value={permanentState ? usState.find(x => x.label === permanentState) : null}
                isDisabled={!editMode}
                placeholder={`${editMode ? 'Select state...' : 'Not indicated'}`}
                styles={!editMode ? selectDisableStyle : selectCustomStyle}
                id="statePermanentSelect"
                instanceId="statePermanentSelect"
              />
            </div>
            <div className="profile__form__item w25">
              <label className="profile__form__label">Zip/Postcode</label>
              <input
                type="text"
                className="profile__form__input"
                placeholder={`${editMode ? 'Ex: 123456' : 'Not indicated'}`}
                value={permanentZipPostcode ? permanentZipPostcode : ''}
                readOnly={!editMode}
                onChange={(e) => setPermanentZipPostcode(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="profile__form__part">
          <div className="profile__form__group">
            <div className={`profile__form__item long ${onlineProfileError ? 'error' : ''}`}>
              <label className="profile__form__label">Online Profile</label>
              <input
                type="text"
                className="profile__form__input online-profile"
                value={onlineProfile ? onlineProfile : ''}
                readOnly={!editMode}
                onChange={(e) => {
                  setOnlineProfile(e.target.value)
                  validateUrl(e.target.value) ? setOnlineProfileError(false) : setOnlineProfileError(true)
                }}
              />
              <p className="error-message">Not correct url string</p>
              {
                !editMode && (
                  <img
                    src={copyLink}
                    alt="copy icon"
                    className="profile__form__copy-link-img"
                    onClick={onCopyHandler} />
                )
              }
              <span className="profile__form__input--url">www.urecruits.com/ur/</span>
              {displayCopied && (
                <ProfileNotification text="You copied the link" />
              )}
            </div>
          </div>
        </div>
        {
          displayButton && editMode ?
            <div className="profile__form__part last">
              <div className="profile__form__group">
                <div className="profile__form__item buttons">
                  <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
                  </button>
                  <button className="profile__form__submit button--filled" type="submit" disabled={loading}>{loading ? "Saving..." : "Save"}</button>
                </div>
                {success ? <p className="success-message">All changes made</p> : null}
              </div>
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(PersonalDetailsTabAndCv)
