import {memo} from "react";
import deleteIcon from '../../image/icon/delete_ic.svg'

const PopupGalleryItem = ({item, images, setImages}) => {

  const onRemove = () => {
    setImages([...images].filter(x => x.name !== item.name))
  }

  return (
    <div className="popup-gallery__item" onClick={onRemove}>
      <img
        src={deleteIcon}
        className="popup-gallery__item__delete"
        alt="delete icon"
      />
      <img
        src={URL.createObjectURL(item)}
        alt="user name"
        className="popup-gallery__item__image"
      />
    </div>
  )
}

export default memo(PopupGalleryItem)