import axios from "axios";

const deleteData = async (api,obj?) => {
  const token: string = localStorage.getItem('token')
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...( obj ? {data:obj} : null)
  }
  return await axios.delete(api, config)

}
export default deleteData