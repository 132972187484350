import IntegrationItem from './IntegrationItem'
import { useLayoutEffect, useState } from 'react'
import axios from 'axios'
import {getEnv} from "@urecruits/api";
const { API_RECRUITMENT } = getEnv();
const token: string = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
};

//TODO: need to change interface
interface ICategoryItem {
  name: string;
}

interface IIntegrationItem {
  name: string;
  description: string | null;
  integration_category: {
    name: string;
  };
  image: {
    url: string;
  };
}


const Integrations = () => {
  const token: string = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const { API_RECRUITMENT } = getEnv();

  const [category, setCategory] = useState<Array<ICategoryItem>>([]);
  const [integrations, setIntegrations] = useState<Array<IIntegrationItem>>([]);
  const [connectionIntegrations, setConnectionIntegrations] = useState([]);

  useLayoutEffect(() => {
    axios
      .get(`https://cms-dev.urecruits.com/integration-categories`)
      .then((res) => {
        if (res.status == 200) {
          setCategory(res.data);
        }
      });

    axios
      .get(`https://cms-dev.urecruits.com/integrations`)
      .then(async (res) => {
        if (res.status == 200) {
         setIntegrations(res.data);
        }
      });

    axios
      .get(
        `${API_RECRUITMENT}/api/integrations/connection-status?providers=gmail,gcal,outlook,mcal,not-exists-provider`,
        config
      )
      .then((res) => {
        if (res.status == 200) {
          setConnectionIntegrations(res.data);
        }
      });
  }, []);

  return (
    <div className="profile__right__inner integrations">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">Integrations</p>
        </div>
      </div>
      <div className="integrations">
        {integrations &&
          connectionIntegrations &&
          category.map((item, index) => {
            return (
              <IntegrationItem
                category={item}
                key={index}
                integrations={integrations}
                connectionIntegrations={connectionIntegrations}
                setIntegrations={setIntegrations}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Integrations;