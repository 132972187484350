import putData from '../../../hook/putData'
import convertDataToString from '../../../hook/convertDataToString'
import {memo} from "react";
import {getEnv} from "@urecruits/api";

const ChangeRolePopup = ({ setRolesPopupData, rolesPopupData, setMembersRefetch, setDataRefetch }) => {

  const onClosePopup = () => {
	  setRolesPopupData({
		  visible: false,
		  userName: '',
		  userAuthId: '',
		  prevRoles: [],
		  newRoles: []
	  })
    setMembersRefetch(new Date())
    setDataRefetch(new Date())
  }

  const {API_RECRUITMENT}=getEnv()

  const onSubmit = async () => {
    const res = await putData(`${API_RECRUITMENT}/api/company/member-roles`, {
      authId: rolesPopupData.userAuthId,
      roles: rolesPopupData.newRoles
    })

    if (res.status === 200) {
      onClosePopup()
      setMembersRefetch(new Date())
      setDataRefetch(new Date())
    }
  }

  return (
    <div className="change-user">
      <div className="change-user__inner">
        <div className="change-user__head">
          <p className="change-user__head__title">
            Change Role
          </p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="change-user__head__close"
            onClick={onClosePopup}
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="change-user__body">
          <p className="change-user__body__information role">
            Are you sure you want to
            change {rolesPopupData.prevRoles.length === 1 ? 'role' : 'roles'}
            <strong>{convertDataToString(rolesPopupData.prevRoles)} to
              {convertDataToString(rolesPopupData.newRoles)}</strong> for this
            member: <strong>{rolesPopupData.userName}?</strong> This action will change the permissions for this
            user.
          </p>
          <div className="change-user__body__buttons">
            <button
              className="change-user__body__cancel button--empty"
              onClick={onClosePopup}
            >
              Cancel
            </button>
            <button
              className="change-user__body__confirm button--filled"
              onClick={onSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ChangeRolePopup)