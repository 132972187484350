import PaymentCardItem from './PaymentCardItem'
import PaymentChangeForm from './PaymentChangeForm'
import { memo, useEffect, useState } from 'react'
import fetchData from '../../../hook/fetchData'
import PaymentCreateForm from './PaymentCreateForm'
import DeletePopup from '../../DeletePopup'
import SmallLoader from '../../SmallLoader'
import { getEnv } from "@urecruits/api"
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle'

const plusIcon = require('../../../image/icon/plus_ic.svg')
const arrowDownIcon = require('../../../image/icon/small-arrow-down_ic.svg')

const emptyCard = {
  billing_details: { name: 'Full name' },
  brand: 'visa',
  expMonth: 'XX',
  expYear: 'XX',
  id: '',
  last4: 'XXXX',
}

const PaymentMethodsTab = ({ usState }) => {
  const {checkUserPermission} = useHasPermission()
  const [loader, setLoader] = useState(false)
  const [deleteCardPopup, setDeleteCardPopup] = useState({
    visible: false,
    cardId: '',
    defaultCard: null,
  })

  const [refetch, setRefetch] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [displayCardId, setDisplayCardId] = useState('')
  const [addedMore, setAddedMore] = useState(false)
  const [addedMoreButtonDisplay, setAddedMoreButtonDisplay] = useState(false)
  const [mobileDisplayAllCard, setMobileDisplayAllCard] = useState(false)
  const [mobileSize, setMobileSize] = useState(false)

  //set true when sm width
  useEffect(() => {
    function updateSize () {
      if (window.innerWidth <= 768) {
        setMobileSize(true)
      } else {
        setMobileSize(false)
      }
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  const {API_RECRUITMENT} = getEnv()

  useEffect(() => {
    if(checkUserPermission('payment-integration','view')){
      setLoader(true)
      fetchData(`${API_RECRUITMENT}/api/payment/get-payment-methods`, setPaymentMethods).then(res => {
        setLoader(false)
        setAddedMoreButtonDisplay(true)
        res.data.length === 0 && setAddedMore(true)
      })
    }
  }, [refetch])

  //set firs card id
  useEffect(() => {
    if (paymentMethods[0]) {
      setDisplayCardId(paymentMethods[0].id)
    }
  }, [paymentMethods])

  const onAddMoreCardHandler = () => {
    setAddedMore(true)
  }

  const displayedCardData = () => {
    return paymentMethods.find((item) => {
      return item.id === displayCardId
    })
  }

  const findDefaultCardData = () => {
    return paymentMethods.find((item) => {
      return item.default
    })
  }

  return (
    <AuthGuard module='payment-integration' permission={['view','edit','add']}>
      <div className="profile__right__inner">
        <div className="profile__head">
          <div className="profile__head__inner">
            <p className="profile__head__title">
              Payment Methods {editMode && (<span> - Editing Mode</span>)}
            </p>
          </div>
        </div>
        <div className="payment">
          <div className="payment__left">
            <p className="payment__title">
              Cards
            </p>
            <div className="payment__left__inner">
              {
                loader ?
                  <SmallLoader/>
                  : paymentMethods.slice(0, !mobileDisplayAllCard && mobileSize ? 1 : paymentMethods.length).map((item, index) => {
                      return (
                      <PaymentCardItem
                        key={index}
                        cardData={item}
                        displayCardId={displayCardId}
                        setDisplayCardId={setDisplayCardId}
                        setEditMode={setEditMode}
                        editMode={editMode}
                        setAddedMore={setAddedMore}
                        addedMore={addedMore}
                        setDeleteCardPopup={setDeleteCardPopup}
                        deleteCardPopup={deleteCardPopup}
                        emptyCard={false}
                      />
                    )
                  })
              }
              {
                addedMore && (
                  <PaymentCardItem
                    cardData={emptyCard}
                    displayCardId={displayCardId}
                    setDisplayCardId={setDisplayCardId}
                    setEditMode={setEditMode}
                    editMode={editMode}
                    setAddedMore={setAddedMore}
                    addedMore={addedMore}
                    setDeleteCardPopup={setDeleteCardPopup}
                    deleteCardPopup={deleteCardPopup}
                    emptyCard={true}
                  />
                )
              }
              {
                addedMoreButtonDisplay && !loader&&paymentMethods.length>1 && (
                  <button className="all-card" onClick={() => setMobileDisplayAllCard(!mobileDisplayAllCard)}>
                    {
                      !mobileDisplayAllCard ?
                        <div className="all-card__text">{`View all cards (${paymentMethods.length})`}<img src={arrowDownIcon} alt="arrow ic"/></div>
                        :
                        <div className="all-card__text rotate">Hide all cards <img src={arrowDownIcon} alt="arrow ic"/></div>
                    }
                  </button>
                )
              }

              {
                addedMoreButtonDisplay && !loader && (
                  <button className={editMode?'payment__left__add payment__left__add--readonly':"payment__left__add"} onClick={onAddMoreCardHandler}>
                    <img src={plusIcon} alt="plus icon" className={editMode?"payment__left__add--icon  editmode":"payment__left__add--icon"}/>
                    <p
                      className="payment__left__add--text"
                    >
                      Add one more card
                    </p>
                  </button>
                )
              }
            </div>
          </div>
          <div className={`payment__right ${loader ? 'loading' : ''}`}>
            {
              !addedMore ?
                <PaymentChangeForm
                  cardData={displayedCardData()}
                  usState={usState}
                  setEditMode={setEditMode}
                  editMode={editMode}
                  setRefetch={setRefetch}
                  defaultCardData={findDefaultCardData() && findDefaultCardData().default ? findDefaultCardData() : []}
                />
                : <PaymentCreateForm
                  usState={usState}
                  setRefetch={setRefetch}
                  setAddedMore={setAddedMore}
                  setLoader={setLoader}
                  paymentMethods={paymentMethods}
                />
            }
          </div>
        </div>
      </div>
      {deleteCardPopup.visible && (
        <DeletePopup
          setDeleteCardPopup={setDeleteCardPopup}
          deleteCardPopup={deleteCardPopup}
          setRefetch={setRefetch}
          title="Delete card"
          description="Are you sure you want to delete this card?"
        />
      )}
    </AuthGuard>
  )
}

export default memo(PaymentMethodsTab)