import {ChangeEvent, memo, useEffect, useState} from 'react'

const searchIcon = require('../../../../image/icon/search_ic.svg')

const MembersTop = ({searchValue, setSearchValue, membersCount, setOffset}) => {
	const [value, setValue] = useState<string>(searchValue)

	const onFormSubmit = (e) => {
		e.preventDefault()
		setSearchValue(value)
		setOffset(0)
	}

	useEffect(() => {
		setValue(searchValue);
	}, [searchValue]);

	return (
		<div className="members__top">
			<form
				className="members__filter"
				onSubmit={(e) => onFormSubmit(e)}
			>
				<input
					type="text"
					placeholder="Search by name, ID"
					value={value}
					disabled={!(membersCount > 0 || searchValue.trim().length > 0)}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
					// onKeyUp={() => value === '' && setSearchValue('')}
					className="members__filter__input"
				/>
				<button
					className={`members__filter__button ${value?.length > 0 ? 'active' : ''}`}
					// disabled={value?.length === 0}
				>
					<img src={searchIcon} alt="search icon" className="members__filter__icon"/>
				</button>
			</form>
		</div>
	)
}
export default memo(MembersTop)