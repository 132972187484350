import { memo } from 'react'

const filterIcon = require('../../../../image/icon/filter-arrows.svg')

const MembersTableHead = ({ setSortBy, setSortType, sortType, searchValue, membersList }) => {

  return membersList?.count > 0&&(
    <thead className={`members__thead ${membersList?.count > 0  ? 'display': ''}`}>
    <td className="members__thead__td name">
      <div
        className="members__thead__inner"
        onClick={() => {
          setSortBy('firstname')
          setSortType(sortType === 'asc' ? 'desc': 'asc')
        }}
      >
        <p className="members__thead__value">
          Name
        </p>
        <img src={filterIcon} alt="filter icon" className="members__thead__icon"/>
      </div>
    </td>
    <td className="members__thead__td id">
      <div
        className="members__thead__inner"
        onClick={() => {
          setSortBy('id')
          setSortType(sortType === 'asc' ? 'desc': 'asc')
        }}
      >
        <p
          className="members__thead__value">
          ID
        </p>
        <img src={filterIcon} alt="filter icon" className="members__thead__icon"/>
      </div>
    </td>
    <td className="members__thead__td job">
      <div
        className="members__thead__inner"
        onClick={() => {
          setSortBy('position')
          setSortType(sortType === 'asc' ? 'desc': 'asc')
        }}
      >
        <p className="members__thead__value">
          Job Title
        </p>
        <img src={filterIcon} alt="filter icon" className="members__thead__icon"/>
      </div>
    </td>
    <td className="members__thead__td role">
      <div className="members__thead__inner">
        <p className="members__thead__value">
          Role
        </p>
      </div>
    </td>
    <td className="members__thead__td action">
      <div className="members__thead__inner">
        <p className="members__thead__value">
          Actions
        </p>
      </div>
    </td>
    </thead>
  )
}
export default memo(MembersTableHead)