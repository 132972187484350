export const currencyData=
[
{"label":"AED", "value": "United Arab Emirates Dirham"},
{"label":"AFN", "value": "Afghan Afghani"},
{"label":"ALL", "value": "Albanian Lek"},
{"label":"AMD", "value": "Armenian Dram"},
{"label":"ANG", "value": "Netherlands Antillean Guilder"},
{"label":"AOA", "value": "Angolan Kwanza"},
{"label":"ARS", "value": "Argentine Peso"},
{"label":"AUD", "value": "Australian Dollar"},
{"label":"AWG", "value": "Aruban Florin"},
{"label":"AZN", "value": "Azerbaijani Manat"},
{"label":"BAM", "value": "Bosnia-Herzegovina Convertible Mark"},
{"label":"BBD", "value": "Barbadian Dollar"},
{"label":"BCH", "value": "Bitcoin Cash"},
{"label":"BDT", "value": "Bangladeshi Taka"},
{"label":"BGN", "value": "Bulgarian Lev"},
{"label":"BHD", "value": "Bahraini Dinar"},
{"label":"BIF", "value": "Burundian Franc"},
{"label":"BMD", "value": "Bermudan Dollar"},
{"label":"BND", "value": "Brunei Dollar"},
{"label":"BOB", "value": "Bolivian Boliviano"},
{"label":"BRL", "value": "Brazilian Real"},
{"label":"BSD", "value": "Bahamian Dollar"},
{"label":"BTC", "value": "Bitcoin"},
{"label":"BTG", "value": "Bitcoin Gold"},
{"label":"BWP", "value": "Botswanan Pula"},
{"label":"BZD", "value": "Belize Dollar"},
{"label":"CAD", "value": "Canadian Dollar"},
{"label":"CDF", "value": "Congolese Franc"},
{"label":"CHF", "value": "Swiss Franc"},
{"label":"CLP", "value": "Chilean Peso"},
{"label":"CNH", "value": "Chinese Yuan (offshore)"},
{"label":"CNY", "value": "Chinese Yuan"},
{"label":"COP", "value": "Colombian Peso"},
{"label":"CRC", "value": "Costa Rican Colón"},
{"label":"CUC", "value": "Cuban Convertible Peso"},
{"label":"CUP", "value": "Cuban Peso"},
{"label":"CVE", "value": "Cape Verdean Escudo"},
{"label":"CZK", "value": "Czech Republic Koruna"},
{"label":"DASH", "value": "DASH"},
{"label":"DJF", "value": "Djiboutian Franc"},
{"label":"DKK", "value": "Danish Krone"},
{"label":"DOP", "value": "Dominican Peso"},
{"label":"DZD", "value": "Algerian Dinar"},
{"label":"EGP", "value": "Egyptian Pound"},
{"label":"EOS", "value": "EOS"},
{"label":"ETB", "value": "Ethiopian Birr"},
{"label":"ETH", "value": "Ethereum"},
{"label":"EUR", "value": "Euro"},
{"label":"FJD", "value": "Fijian Dollar"},
{"label":"GBP", "value": "British Pound Sterling"},
{"label":"GEL", "value": "Georgian Lari"},
{"label":"GHS", "value": "Ghanaian Cedi"},
{"label":"GIP", "value": "Gibraltar Pound"},
{"label":"GMD", "value": "Gambian Dalasi"},
{"label":"GNF", "value": "Guinean Franc"},
{"label":"GTQ", "value": "Guatemalan Quetzal"},
{"label":"GYD", "value": "Guyanaese Dollar"},
{"label":"HKD", "value": "Hong Kong Dollar"},
{"label":"HNL", "value": "Honduran Lempira"},
{"label":"HRK", "value": "Croatian Kuna"},
{"label":"HTG", "value": "Haitian Gourde"},
{"label":"HUF", "value": "Hungarian Forint"},
{"label":"IDR", "value": "Indonesian Rupiah"},
{"label":"ILS", "value": "Israeli New Sheqel"},
{"label":"INR", "value": "Indian Rupee"},
{"label":"IQD", "value": "Iraqi Dinar"},
{"label":"IRR", "value": "Iranian Rial"},
{"label":"ISK", "value": "Icelandic Króna"},
{"label":"JMD", "value": "Jamaican Dollar"},
{"label":"JOD", "value": "Jordanian Dinar"},
{"label":"JPY", "value": "Japanese Yen"},
{"label":"KES", "value": "Kenyan Shilling"},
{"label":"KGS", "value": "Kyrgystani Som"},
{"label":"KHR", "value": "Cambodian Riel"},
{"label":"KMF", "value": "Comorian Franc"},
{"label":"KRW", "value": "South Korean Won"},
{"label":"KWD", "value": "Kuwaiti Dinar"},
{"label":"KYD", "value": "Cayman Islands Dollar"},
{"label":"KZT", "value": "Kazakhstani Tenge"},
{"label":"LAK", "value": "Laotian Kip"},
{"label":"LBP", "value": "Lebanese Pound"},
{"label":"LKR", "value": "Sri Lankan Rupee"},
{"label":"LRD", "value": "Liberian Dollar"},
{"label":"LSL", "value": "Lesotho Loti"},
{"label":"LTC", "value": "Litecoin"},
{"label":"LYD", "value": "Libyan Dinar"},
{"label":"MAD", "value": "Moroccan Dirham"},
{"label":"MDL", "value": "Moldovan Leu"},
{"label":"MKD", "value": "Macedonian Denar"},
{"label":"MMK", "value": "Myanma Kyat"},
{"label":"MOP", "value": "Macanese Pataca"},
{"label":"MUR", "value": "Mauritian Rupee"},
{"label":"MVR", "value": "Maldivian Rufiyaa"},
{"label":"MWK", "value": "Malawian Kwacha"},
{"label":"MXN", "value": "Mexican Peso"},
{"label":"MYR", "value": "Malaysian Ringgit"},
{"label":"MZN", "value": "Mozambican Metical"},
{"label":"NAD", "value": "Namibian Dollar"},
{"label":"NGN", "value": "Nigerian Naira"},
{"label":"NIO", "value": "Nicaraguan Córdoba"},
{"label":"NOK", "value": "Norwegian Krone"},
{"label":"NPR", "value": "Nepalese Rupee"},
{"label":"NZD", "value": "New Zealand Dollar"},
{"label":"OMR", "value": "Omani Rial"},
{"label":"PAB", "value": "Panamanian Balboa"},
{"label":"PEN", "value": "Peruvian Nuevo Sol"},
{"label":"PGK", "value": "Papua New Guinean Kina"},
{"label":"PHP", "value": "Philippine Peso"},
{"label":"PKR", "value": "Pakistani Rupee"},
{"label":"PLN", "value": "Polish Zloty"},
{"label":"PYG", "value": "Paraguayan Guarani"},
{"label":"QAR", "value": "Qatari Rial"},
{"label":"RON", "value": "Romanian Leu"},
{"label":"RSD", "value": "Serbian Dinar"},
{"label":"RUB", "value": "Russian Ruble"},
{"label":"RWF", "value": "Rwandan Franc"},
{"label":"SAR", "value": "Saudi Riyal"},
{"label":"SBD", "value": "Solomon Islands Dollar"},
{"label":"SCR", "value": "Seychellois Rupee"},
{"label":"SDG", "value": "Sudanese Pound"},
{"label":"SEK", "value": "Swedish Krona"},
{"label":"SGD", "value": "Singapore Dollar"},
{"label":"SLL", "value": "Sierra Leonean Leone"},
{"label":"SOS", "value": "Somali Shilling"},
{"label":"SRD", "value": "Surinamese Dollar"},
{"label":"SVC", "value": "Salvadoran Colón"},
{"label":"SZL", "value": "Swazi Lilangeni"},
{"label":"THB", "value": "Thai Baht"},
{"label":"TJS", "value": "Tajikistani Somoni"},
{"label":"TMT", "value": "Turkmenistani Manat"},
{"label":"TND", "value": "Tunisian Dinar"},
{"label":"TOP", "value": "Tongan Paʻanga"},
{"label":"TRY", "value": "Turkish Lira"},
{"label":"TTD", "value": "Trinidad and Tobago Dollar"},
{"label":"TWD", "value": "New Taiwan Dollar"},
{"label":"TZS", "value": "Tanzanian Shilling"},
{"label":"UAH", "value": "Ukrainian Hryvnia"},
{"label":"UGX", "value": "Ugandan Shilling"},
{"label":"USD", "value": "United States Dollar"},
{"label":"UYU", "value": "Uruguayan Peso"},
{"label":"UZS", "value": "Uzbekistan Som"},
{"label":"VND", "value": "Vietnamese Dong"},
{"label":"XAF", "value": "CFA Franc BEAC"},
{"label":"XAG", "value": "Silver (troy ounce)"},
{"label":"XAU", "value": "Gold (troy ounce)"},
{"label":"XCD", "value": "East Caribbean Dollar"},
{"label":"XLM", "value": "Stellar Lumens"},
{"label":"XOF", "value": "CFA Franc BCEAO"},
{"label":"XRP", "value": "Ripple"},
{"label":"YER", "value": "Yemeni Rial"},
{"label":"ZAR", "value": "South African Rand"},
{"label":"ZMW", "value": "Zambian Kwacha"}
]