import { memo } from "react";
const PopupWithList = ({ addOnUsage, onClose }) => {
	const title = addOnUsage && addOnUsage.length ? addOnUsage[0].addonName + " Usage Records" : '';
	let previousUnits: number = 0;
	let previousQuantity: number = 0;
	let totalUsage: number = 0;
	let totalUnit: number = 0;
	let prevUsage: {units: number, quantity:number} = {units: 0, quantity: 0};
	addOnUsage = addOnUsage.filter(item=> {
		if(prevUsage.units != item.usage && prevUsage.quantity != item.quantity){
			prevUsage.units = item.units;
			prevUsage.quantity = item.quantity;
			return true
		}return false
	});
	return <div className="popup">
		<div className="popup__step">
			<div className="popup__head">
				<p className="popup__head__headline">
					{title}
				</p>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={onClose}
					className="popup__head__close"
				>
					<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round" />
				</svg>
			</div>
			<div className="popup__body">
				<table>
				<thead>
					<th>Usage Date</th>
					<th>Unit</th>
					<th>Usage</th>

				</thead>
				<tbody>
					{
						addOnUsage.map((item, index) => {
							const date = new Date(item.createdAt);
							let status;
							if (previousUnits === 0) {
								if (item.units === 1) {
									status = '1 added/retained';
								} else {
									status = `${item.units} retained`;
								}

							} else if (previousUnits < item.units) {
								status = '1 added';
							} else if (previousUnits > item.units) {
								status = '1 removed';
							} else if (previousUnits === item.units) {
								status = `${item.units} retained`;
							}
							previousUnits = item.units;
							const currentQuantity = item.quantity - previousQuantity >= 0 ? item.quantity - previousQuantity : 0;
							previousQuantity = item.quantity;
							totalUsage = item.quantity;
							totalUnit = item.units;
							return <tr key={item.id}>
								<td>{date.getDate()} {date.toLocaleString('en-US', { month: 'short' })} {date.toLocaleString('en-US', { year: '2-digit' })}</td>
								<td>{status}</td>
								<td>{currentQuantity}</td>
							</tr>
						})
					}
					<tr>
						<td>Total</td>
						<td>{totalUnit}</td>
						<td>{totalUsage}</td>
					</tr>
				</tbody>
				</table>
			</div>
		</div>
	</div>
}
export default memo(PopupWithList)