const filterIcon = require('../../../../image/icon/filter-arrows.svg')

const RolesTableHead = ({
  setSortedBy,
  sortedBy
}) => {

  return (
    <div className="roles-table__head">
      <div className="roles-table__head__item role">
        <div
          className="roles-table__head__inner"
          onClick={() => {
            setSortedBy(() => ({ method: sortedBy.method === 'asc' ? 'desc' : 'asc', sortValue: 'role' }))
          }}
        >
          <p className="roles-table__head__text">
            Role
          </p>
          <img src={filterIcon} alt="filter icon" className="roles-table__head__icon"/>
        </div>
      </div>
      <div className="roles-table__head__item users">
        <div
          className="roles-table__head__inner"
          onClick={() => {
            setSortedBy(() => ({ method: sortedBy.method === 'asc' ? 'desc' : 'asc', sortValue: 'usersCount' }))
          }}
        >
          <p className="roles-table__head__text">
            # Users
          </p>
          <img src={filterIcon} alt="filter icon" className="roles-table__head__icon"/>
        </div>
      </div>
      <div className="roles-table__head__item description">
        <div
          className="roles-table__head__inner"
          onClick={() => {
            setSortedBy(() => ({ method: sortedBy.method === 'asc' ? 'desc' : 'asc', sortValue: 'description' }))
          }}
        >
          <p className="roles-table__head__text">
            Description
          </p>
          <img src={filterIcon} alt="filter icon" className="roles-table__head__icon"/>
        </div>
      </div>
      <div className="roles-table__head__item permissions">
        <div className="roles-table__head__inner">
          <p className="roles-table__head__text">
            Actions
          </p>
        </div>
      </div>
    </div>
  )
}

export default RolesTableHead