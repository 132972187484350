import { useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { memo } from "react";

const backArrow = require('../../../image/icon/arrow_back_ic.svg')

const AccessManagementHead = ({ activeTab, activeRole, setActiveRole, setActiveTab, setDisplayAccessPopup }) => {
  const {checkUserPermission} = useHasPermission()

  return (
    <div className="access-management__head">
      {
        activeTab.length !== 0 && (
          <div
            className="access-management__head__back"
            onClick={() => {
              setActiveRole({ ...activeRole, name: '', authId: '' })
              setActiveTab('')
            }}
          >
            <img src={backArrow} alt="back arrow" className="access-management__head__back--icon"/>
            <p className="access-management__head__text">Back</p>
            <p className="access-management__head__text mobile">{activeRole.name.split("_")?.[0]}</p>
          </div>
        )
      }
      <div className="access-management__head__inner">
        <div className="access-management__head__left">
          <p className={`access-management__head__headline ${activeTab === '' ? 'manage' : ''}`}>
            {
              activeTab.length === 0 && (
                'Manage Roles'
              )
            }
            {
              activeTab.length !== 0 && activeRole?.name?.length !== 0 && (
                activeRole.name.split("_")?.[0]
              )
            }
          </p>
          {
            activeTab.length !== 0 && (
              <div className="access-management__head__switch">
                <div
                  className={`access-management__head__button ${activeTab === 'members' ? 'active' : ''}`}
                  onClick={() => setActiveTab('members')}
                >
                  Members
                </div>
                <div
                  className={`access-management__head__button ${activeTab === 'permissions' ? 'active' : ''}`}
                  onClick={() => setActiveTab('permissions')}
                >
                  Permissions
                </div>
              </div>
            )
          }
        </div>
        {
          activeTab === 'permissions' && checkUserPermission('team-members','view') && (
            <div className="access-management__head__right">
              <div
                className="access-management__head__modules"
                onClick={() => setDisplayAccessPopup(true)}
              >
                View all modules access
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default memo(AccessManagementHead)