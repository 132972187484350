import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { memo, useEffect, useState } from "react";
import InvoiceHistoryComponent from './InvoiceHistoryComponent';
import fetchData from '../../../hook/fetchData';
import UpcomingInvoiceComponent from './UpcomingInvoiceComponent';
import { getEnv } from '@urecruits/api';

const { API_RECRUITMENT } = getEnv()
const InvoicesCard = ({
}) => {

  // const [upcomingInvoice, setUpcomingInvoice] = useState([]);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  useEffect(() => {
    fetchData(`${API_RECRUITMENT}/api/payment/company/get-upcoming-invoice`, (data) => data).then(data => {
      if (data.data) {
        setUpcomingInvoice(data.data);
      }
    })
  }, [])
  

  return (
    <AuthGuard module='subscription' permission='view'>
      <AuthGuard module='subscription' permission={['edit', 'add']}>
        <div className="invoices__inner">
          { upcomingInvoice && upcomingInvoice.length && upcomingInvoice.map((data,index) => <UpcomingInvoiceComponent key={index} data={data} isAddon={index>0} />)}
          <InvoiceHistoryComponent/>
        </div>
      </AuthGuard>
    </AuthGuard>
  )
}

export default memo(InvoicesCard)