import axios from "axios";

const patchData = async (api,data) => {
	const token: string = localStorage.getItem('token')
	const config = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}
	return await axios.patch(api, data, config)
}
export default patchData