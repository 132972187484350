import { default as ReactSelect } from 'react-select'
import { selectSmallStyle } from '../../../../styles/selectSmallStyle'
import ReactPaginate from 'react-paginate'
import {memo, useEffect, useState} from 'react'

const selectOption = [
  { value: 100, label: '100' },
  { value: 50, label: '50' },
  { value: 20, label: '20' },
  { value: 10, label: '10' },
  { value: 5, label: '5' },
  { value: 3, label: '3' },
]


const MembersBottom = ({ pagesCount, setLimit, limit, setOffset }) => {
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [pagesCount, setLimit, limit]);

  return (
    <div className="members__bottom">
      <div className="members__bottom__left">
        <p className="members__bottom__text">
          Page {currentPage} of {pagesCount == 0 ? '1' : pagesCount}
        </p>
      </div>
      {
        pagesCount > 1 && (
          <div className="members__bottom__center">
            <ReactPaginate
              breakLabel=" ... "
              nextLabel="❯"
              pageCount={pagesCount}
              onPageChange={(e) => {
                setCurrentPage(e.selected + 1)
                setOffset(e.selected * limit)
              }}
              previousLabel="❮"
              containerClassName="pagination"
              pageClassName="pagination__item"
              activeClassName="active"
              nextClassName="pagination__next"
              previousClassName="pagination__prev"
              pageLinkClassName="pagination__link"
            />
          </div>
        )
      }
      <div className="members__bottom__right">
        <p className="members__bottom__text">Show</p>
        <div className="members__bottom__select">
          <ReactSelect
            options={selectOption}
            closeMenuOnSelect={true}
            isSearchable={false}
            onChange={(currentValue: any) => {
              setLimit(currentValue.value)
              setOffset(0)
              setCurrentPage(1)
            }}
            value={limit ? selectOption.find(x => x.value === limit) : null}
            styles={selectSmallStyle}
            id="itemsOnPageSelect"
            instanceId="itemsOnPageSelect"
          />
        </div>
        <p className="members__bottom__text">on page</p>
      </div>
    </div>
  )
}

export default memo(MembersBottom)