import axios from "axios";

export const postData = async (api, data) => {
  const token: string = localStorage.getItem('token') || "";
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }
  const res = await axios.post(api, data, config);
  if (res && res.data) {
    return res.data;
  }
};

export default postData;
