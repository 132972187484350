import deleteData from '../hook/deleteData'
import {memo, useState} from 'react'
import {getEnv} from "@urecruits/api";

const deleteIc = require('../image/icon/delete_image.svg')

const DeletePopup = ({ setDeleteCardPopup, deleteCardPopup, title, description, setRefetch }) => {
  const [errorNotification, setErrorNotification] = useState('')
  const {API_RECRUITMENT} = getEnv()

  const onDeleteCardHandler = async () => {
    if (!deleteCardPopup.defaultCard) {
      setDeleteCardPopup({ ...deleteCardPopup, visible: false })

      await deleteData(`${API_RECRUITMENT}/api/payment/delete-payment-method`, {
        id: deleteCardPopup.cardId,
      })
      setRefetch(new Date())
    } else {
      setErrorNotification('You cannot delete a default card')
    }
  }

  const onClosePopup = () => {
    setErrorNotification('')
    setDeleteCardPopup({ ...deleteCardPopup, visible: false })
  }

  return (
    <div className="popup">
      <div className="popup__step">
        <div className="popup__step__head">
          <p className="popup__step__headline">
            {title}
          </p>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
               className="popup__step__close"
               onClick={onClosePopup}
          >
            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
        <div className="popup__step__body">
          <img src={deleteIc} alt="delete icon" className="popup__step__image"/>
          <p className="popup__step__info">
            {description}
          </p>
        </div>
        <div className="popup__step__control end">
          <div
            className="popup__step__button button--empty"
            onClick={() => setDeleteCardPopup({ ...deleteCardPopup, visible: false })}
          >
            Cancel
          </div>
          <div
            className="popup__step__button button--filled"
            onClick={onDeleteCardHandler}
          >
            Delete
          </div>
          {
            errorNotification.length > 0 && (
              <p className="error-message">{errorNotification}</p>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default memo(DeletePopup)