import { memo, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AwardsDetailsTabItem from './AwardsDetailsTabItem';
import { getEnv } from "@urecruits/api";
import patchData from "../../../hook/patchData";

const editIc = require('../../../image/icon/edit_ic.svg')
const plusIc = require('../../../image/icon/plus_ic.svg')

const AwardsDetailsTab = ({ data, setRefetch, setData }) => {
  const token: string = localStorage.getItem('token')
  const [validateStatus, setValidateStatus] = useState(false)
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  const [addedMoreClicked, setAddedMoreClicked] = useState(false)
  const addedMoreEducationButtonRef = useRef(null)
  const submitButtonRef = useRef(null)

  //form state
  const [dataDetails, setDataDetails] = useState([])

  useEffect(() => {
    if (data) {
      setDataDetails(data.awards)
      setAddedMoreClicked(() => false)
      const isEditMode = data.awards.some(item => item?.isResumeParsed);
      setEditMode(editMode ? editMode : isEditMode)
      setDisplayButton(editMode ? editMode : isEditMode)
    }
  }, [data])
  const {API_RECRUITMENT}=getEnv()
  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)

    setDataDetails(data.awards)

    const awards = data.awards.filter(item => !item.isResumeParsed);
    setDataDetails(awards);
    setData(oldData => {
      return {...oldData,awards}
    })
  }

  function setDataOnSuccess(res,itemId) {
    if (res.status === 200 || res.status === 201) {
      setSuccess(true)
      setData(oldData => {
        let awards = [...oldData?.awards];
        oldData.awards = awards?.map(i => i.id === itemId ? { ...res.data } : i)
        return { ...oldData }
      });
    } else {
      setSuccess(false)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setValidateStatus(false)
    if (validateStatus) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
      setSuccess(true)
      //Need to added post
      for (const itemMain of dataDetails) {
        const item = {...itemMain}
        if(!item?.isResumeParsed) {
          await patchData(`${API_RECRUITMENT}/api/candidate/award`, item).then((res) => {
            setDataOnSuccess(res,item.id);
          })
        } else {
          const itemId = item.id
          delete item.id;
          await axios.post(`${API_RECRUITMENT}/api/candidate/award`, item, config).then((res) => {
            setDataOnSuccess(res,itemId);
          }).catch(err => {
            console.log(err)
          })
        }
      }

      setTimeout(() => {
        setSuccess(false)
        setEditMode(false)
        setDisplayButton(false)
      }, 2000)
    }
  }

  const onAddEducationHandler = async () => {
    if (editMode) {
      // empty object
      const obg = {
        name: '',
        companyName: '',
        date: null,
        notes: '',
        fileKey: '',
        fileName: 'filename.pdf',
        candidateId: data.id,
      }
      setValidateStatus(false)
      if (validateStatus && !addedMoreClicked || !dataDetails?.length) {
        setAddedMoreClicked(() => true)

        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
        for (const item of dataDetails) {
          await patchData(`${API_RECRUITMENT}/api/candidate/award`, item).then((res) => {
          }).catch(e => console.log(e))
        }
        //Need to added post, and send pdf logic
        await axios.post(`${API_RECRUITMENT}/api/candidate/award`, obg, config).then((res) => {

          setRefetch(new Date())

        }).catch(err => {
          console.log(err)
        })
      }
    }
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Awards {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        {
          dataDetails.map((item, index) => {
            return (
              <AwardsDetailsTabItem
                dataDetails={dataDetails} 
                currentItem={item}
                setDataDetails={setDataDetails}
                editMode={editMode}
                setEditMode={setEditMode}
                setData={setData}
                key={index}
                validateStatus={validateStatus}
                setValidateStatus={setValidateStatus}
                displayButton={displayButton}
                addedMoreEducationButtonRef={addedMoreEducationButtonRef}
                submitButtonRef={submitButtonRef}
                index={index}
                setRefetch={setRefetch}
                setDisplayButton={setDisplayButton}
              />
            )
          })
        }
        {
          editMode ?
            <div className="profile__form__group">
              <div className={`profile__form__more`}
                   id="educationsMoreButton"
                   ref={addedMoreEducationButtonRef}
                   onClick={() => onAddEducationHandler()}
              >
                <img src={plusIc} alt="plust icon" className="profile__form__more--icon"/>
                <p className="profile__form__more--text">Add one more award</p>
              </div>
            </div>
            : null
        }

        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel
                </button>
                <button className="profile__form__submit button--filled" type="submit" ref={submitButtonRef}>Save
                </button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default memo(AwardsDetailsTab)