import DatePicker from "react-datepicker";
import {memo, useEffect, useState} from "react";
import deleteData from "../../../hook/deleteData";
import {getEnv} from "@urecruits/api";
import patchData from "../../../hook/patchData";
import { Box, Chip } from "@mui/material";

const deleteIc = require("../../../image/icon/delete_ic.svg");

const EducationsDetailsTabItem =
	({
		 editMode,
		 item,
		 setEducationsDetails,
		 educationsDetails,
		 setValidateStatus,
		 validateStatus,
		 displayButton,
		 addedMoreEducationButtonRef,
		 submitButtonRef,
		 index, setRefetch,
		 setData,
		 setDisplayButton,
	 }) => {
		const [universityNameError, setUniversityNameError] = useState(editMode ? !item?.universityName : false);
		const [courseNameError, setCourseNameError] = useState(editMode ? !item?.courseName : false);
		const [specificationsError, setSpecificationsError] = useState(editMode ? !item?.specification : false);
		const [coursePeriodsStartError, setCoursePeriodsStartError] = useState(editMode ? !item?.coursePeriodStart : false);
		const [coursePeriodsEndError, setCoursePeriodsEndError] = useState(editMode ? !item?.coursePeriodEnd : false);
		const [percentageError, setPercentageError] = useState(editMode ? !item?.gpa : false);

		const fieldsValidate = () => {
			let error = 0;
			educationsDetails.map((object) => {
				!object.universityName || object.universityName.length <= 0 ? setUniversityNameError(true) : setUniversityNameError(false);
				!object.courseName || object.courseName.length <= 0 ? setCourseNameError(true) : setCourseNameError(false);
				!object.specification || object.specification.length <= 0 ? setSpecificationsError(true) : setSpecificationsError(false);
				!object.gpa || object.gpa.length <= 0 ? setPercentageError(true) : setPercentageError(false);
				!object.coursePeriodStart ? setCoursePeriodsStartError(true) : setCoursePeriodsStartError(false);
				(!object.coursePeriodEnd && !object.present) ? setCoursePeriodsEndError(true) : setCoursePeriodsEndError(false);

				if (!object.specification || !object.courseName || !object.gpa || !object.universityName || object.universityName.length <= 0 || object.specification.length <= 0 || !object.coursePeriodStart || (!object.coursePeriodEnd && !object.present)) {
					error++;
				}
			});

			if (error === 0) {
				setValidateStatus(true);
			}
		};

		useEffect(() => {
			if (!validateStatus && editMode) {
				//added more click validate
				addedMoreEducationButtonRef.current.onclick = function () {
					fieldsValidate();
				};

				//on submit click validate
				if (displayButton) {
					submitButtonRef.current.onclick = function () {
						fieldsValidate();
					};
				}
			}
		}, [validateStatus, educationsDetails, item, editMode]);
		const {API_RECRUITMENT} = getEnv();

		const onRemoveItemHandler = async (e) => {
			e.preventDefault;
			if (editMode) {
				if (item?.isResumeParsed) {
					setData(oldData => {
						let educations = [];
						educations = educationsDetails?.filter(i => i.id !== item.id)
						oldData.educations = educations;
						return { ...oldData }
					});
				} else {
					const res = await deleteData(`${API_RECRUITMENT}/api/candidate/education`, item);
					if (res.status == 200) {
						setData(oldData => {
							const _oldData = JSON.parse(JSON.stringify(oldData));
							let educations = educationsDetails;
							educations = educations.filter(i => i.id !== item.id);
							return { ..._oldData, educations }
						})
					}
				}
			}
		};

		return (
			<>
				<div className="profile__form__part">
					<div className={`flex`}>
						<Box>
							{item?.isResumeParsed && <Chip variant='outlined' color='error' label="Unsaved" />}
						</Box>
						<div className={`profile__form__delete ${!editMode ? "hide" : ""}`}>
							<div className="profile__form__delete--inner" onClick={(e) => onRemoveItemHandler(e)}>
								<span className="profile__form__delete--text">Delete</span>
								<img src={deleteIc} alt="delete icon" className="profile__form__delete--icon" />
							</div>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item long ${universityNameError ? "error" : ""}`}>
							<label className="profile__form__label">University/Institute Name<span> *</span></label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Select University/Institute name" : "Not indicated"}`}
								readOnly={!editMode}
								value={item.universityName ? item.universityName : ""}
								onChange={(e) => {
									setEducationsDetails([...educationsDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												universityName: e.target.value,
											};
										} else return object;
									}));
									e.target.value.length <= 0 ? setUniversityNameError(true) : setUniversityNameError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item  ${courseNameError ? "error" : ""}`}>
							<label className="profile__form__label">Course Name<span> *</span></label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter your course name" : "Not indicated"}`}
								readOnly={!editMode}
								value={item.courseName ? item.courseName : ""}
								onChange={(e) => {
									setEducationsDetails([...educationsDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												courseName: e.target.value,
											};
										} else return object;
									}));
									e.target.value.length <= 0 ? setCourseNameError(true) : setCourseNameError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item  ${specificationsError ? "error" : ""}`}>
							<label className="profile__form__label">Specification<span> *</span></label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter your specification" : "Not indicated"}`}
								readOnly={!editMode}
								value={item.specification ? item.specification : ""}
								onChange={(e) => {
									setEducationsDetails([...educationsDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												specification: e.target.value,
											};
										} else return object;
									}));
									e.target.value.length <= 0 ? setSpecificationsError(true) : setSpecificationsError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item short after-line ${coursePeriodsStartError ? "error" : ""}`}>
							<label className="profile__form__label">Course Periods<span> *</span></label>
							<div className="profile__form__date">
								<DatePicker
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									selected={item.coursePeriodStart ? new Date(item.coursePeriodStart) : null}
									onChange={(date: any) => {
										setDisplayButton(true);
										setEducationsDetails([...educationsDetails].map(object => {
											if (object.id === item.id) {
												return {
													...object,
													coursePeriodStart: date,
												};
											} else return object;
										}));
										!date ? setCoursePeriodsStartError(true) : setCoursePeriodsStartError(false);
									}}
									dateFormat="MMMM d, yyyy"
									maxDate={new Date()}
									readOnly={!editMode}
									placeholderText="Start of course"
								/>
							</div>
							<p className="error-message">This is required field</p>
						</div>
						<div className={`profile__form__item short ${coursePeriodsEndError ? "error" : ""}`}>
							<div className={`profile__form__date ${!item.coursePeriodStart || item.present ? "profile__form__date--readonly" : ""}`}>
								<DatePicker
									showYearDropdown={true}
									scrollableYearDropdown={true}
									yearDropdownItemNumber={70}
									selected={item.coursePeriodEnd ? new Date(item.coursePeriodEnd) : null}
									disabled={item.present}
									onChange={(date: any) => {
										setDisplayButton(true);
										setEducationsDetails([...educationsDetails].map(object => {
											!date && !object.present ? setCoursePeriodsEndError(true) : setCoursePeriodsEndError(false);

											if (object.id === item.id) {
												return {
													...object,
													coursePeriodEnd: date,
												};
											} else return object;
										}));
										!date ? setCoursePeriodsEndError(true) : setCoursePeriodsEndError(false);
									}}
									dateFormat="MMMM d, yyyy"
									minDate={item.coursePeriodStart ? new Date(item.coursePeriodStart) : null}
									readOnly={!editMode || !item.coursePeriodStart}
									placeholderText="End of course"
								/>
							</div>
							<p className="error-message">This is required field</p>
						</div>
						<div className="profile__form__item present short">
							<label className="profile__form__label"></label>
							<div className="custom-checkbox">
								<input
									type="checkbox"
									className="custom-checkbox__input"
									id={`checkbox${item.id}`}
									name={`checkbox${item.id}`}
									checked={item.present}
									onChange={(e) => {
										setEducationsDetails([...educationsDetails].map(object => {
											if (object.id === item.id) {
												return {
													...object,
													present: !item.present,
												};
											} else return object;
										}));
									}}
								/>
								<label htmlFor={`checkbox${item.id}`} className="custom-checkbox__label">
									<div className="custom-checkbox__item">
										<span></span>
									</div>
									<p className="custom-checkbox__text">This credential does not expire</p>
								</label>
							</div>
						</div>
					</div>
					<div className="profile__form__group">
						<div className={`profile__form__item short ${percentageError ? "error" : ""}`}>
							<label className="profile__form__label">GPA / Percentage<span> *</span></label>
							<input
								type="text"
								className="profile__form__input"
								placeholder={`${editMode ? "Enter GPA / Percentage" : "Not indicated"}`}
								readOnly={!editMode}
								value={item.gpa ? item.gpa : ""}
								onChange={(e) => {
									setEducationsDetails([...educationsDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												gpa: e.target.value,
											};
										} else return object;
									}));
									e.target.value.length <= 0 ? setPercentageError(true) : setPercentageError(false);
								}}
							/>
							<p className="error-message">This is required field</p>
						</div>
					</div>
				</div>
			</>
		);
	};

export default memo(EducationsDetailsTabItem);