import SelectRolesItem from './SelectRolesItem'
import React, { memo, useState } from 'react'
import { RolesContext } from '../company/access/AccessManagementTab'
import convertDataToString from '../../hook/convertDataToString'

const SelectRoles = ({ currentUserData, rolesPopupData, setRolesPopupData }) => {
  const [roleToggle, setRoleToggle] = useState(false)
  const allUsersPermissionData = React.useContext(RolesContext)
  const [newRoleData, setNewRoleData] = useState(currentUserData.roles.map(item => {
    return {
      id:item.id,
      value: item.value,
      label: item.label?.split("-")[0]
    }
  }))

  return (
    <div className={`select-roles ${roleToggle ? 'active' : ''}`}>
      <div
        className="select-roles__head"
        onClick={() => setRoleToggle(!roleToggle)}
      >
        <p className="select-roles__head__value">
          {convertDataToString(currentUserData.roles)}
        </p>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
             className="select-roles__head__icon">
          <path
            d="M7.00065 4.375H2.98808C2.20854 4.375 1.81814 5.3175 2.36936 5.86872L6.38193 9.88128C6.72364 10.223 7.27766 10.223 7.61936 9.88128L11.6319 5.86872C12.1831 5.3175 11.7928 4.375 11.0132 4.375H7.00065Z"
            fill="#999EA5"/>
        </svg>
      </div>
      <form className="select-roles__form">
        {
          allUsersPermissionData?.map((item) => {
            return (
              !item.role.includes('Company Owner')&&<SelectRolesItem
                key={item.auth0_id}
                currentUserData={currentUserData}
                strapiRoleItem={item}
                setRolesPopupData={setRolesPopupData}
                setNewRoleData={setNewRoleData}
                newRoleData={newRoleData}
                setRoleToggle={setRoleToggle}
              />
            )
          })
        }
      </form>
    </div>
  )
}

export default memo(SelectRoles)