import React from "react";
import PermissionSwitcher from "./PermissionSwitcher";
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { usePermissionsTableItem } from "./usePermissionsTableItem";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const PermissionsTableItem = ({ item, role }) => {

  const {checkUserPermission} = useHasPermission()
  const {
    handleToastNotificationClose,
    toastNotification,
    displayInfo,
    setDisplayInfo,
    handleOnPermissionChange,
  } = usePermissionsTableItem(item, role);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <AuthGuard module='team-members' permission={['view','edit']}>
      <Snackbar
        open={toastNotification.state}
        autoHideDuration={1000}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      <div className={`permissions-table__item ${displayInfo ? "active" : ""}`}>
        <div className="permissions-table__left">
          <div
            className="permissions-table__left__module"
            onClick={() => setDisplayInfo(!displayInfo)}
          >
            {item.name}
          </div>
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="permissions-table__left__arrow"
          >
            <g clipPath="url(#clip0_6057_5825)">
              <path
                d="M1 3L5 7L9 3"
                stroke="#999EA5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_6057_5825">
                <rect
                  width="10"
                  height="10"
                  fill="white"
                  transform="translate(0 10) rotate(-90)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="permissions-table__right">
          {item.edit !== null && (
            <PermissionSwitcher
              active={!!item.edit}
              title={"Edit"}
              onChange={(value) => handleOnPermissionChange(value, "edit")}
              {...(!checkUserPermission('team-members','edit') ?{disabled:true} : '')}
            />
          )}
          {item.view !== null && (
            <PermissionSwitcher
              active={!!item.view}
              title={"View"}
              onChange={(value) => handleOnPermissionChange(value, "view")}
              {...(!checkUserPermission('team-members','edit') ?{disabled:true} : '')}
            />
          )}
          {item.read !== null && (
            <PermissionSwitcher
              active={!!item.delete}
              title={"Delete"}
              onChange={(value) => handleOnPermissionChange(value, "delete")}
              {...(!checkUserPermission('team-members','edit') ?{disabled:true} : '')}
            />
          )}
          {item.add !== null && (
            <PermissionSwitcher
              active={!!item.add}
              title={"Create"}
              onChange={(value) => handleOnPermissionChange(value, "add")}
              {...(!checkUserPermission('team-members','edit') ?{disabled:true} : '')}
            />
          )}
        </div>
      </div>
    </AuthGuard>
  );
};

export default PermissionsTableItem;
