import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { default as ReactSelect } from 'react-select'
import { selectCustomStyle } from '../../styles/selectCustomStyle'
import { selectDisableStyle } from '../../styles/selectDisableStyle'
import patchData from "../../hook/patchData"
import { getEnv } from "@urecruits/api"

const editIc = require('../../image/icon/edit_ic.svg')

const statusOption = [
  { value: 'No status', label: 'No status' },
  { value: 'Ready', label: 'Ready' },
  { value: 'At NVC', label: 'At NVC' },
  { value: 'In Transit', label: 'In Transit' },
  { value: 'Issued', label: 'Issued' },
  { value: 'Refused', label: 'Refused' },
  { value: 'Administrative processing', label: 'Administrative processing' },
  { value: 'Expired', label: 'Expired' },
  { value: 'Expiring Soon', label: 'Expiring Soon' },
]

const VisaInformationTab = ({visaOption, data, setRefetch, countryList, nationalityList }) => {
  const [success, setSuccess] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [displayButton, setDisplayButton] = useState(false)
  //form state
  const [passportNumber, setPassportNumber] = useState('')
  const [visaType, setVisaType] = useState('')
  const [issueCountry, setIssueCountry] = useState('')
  const [issuedOnDate, setIssuedOnDate] = useState(null)
  const [expirationDate, setExpirationDate] = useState(null)
  const [nationality, setNationality] = useState('')
  const [status, setStatus] = useState('')

  const setStates=()=>{
    setPassportNumber(data.passport)
    setVisaType(data.visaType)
    setIssueCountry(data.issueCountry)
    setIssuedOnDate(data.issuedOn ? new Date(data.issuedOn) : null)
    setExpirationDate(data.expiration ? new Date(data.expiration) : null)
    setNationality(data.nationality)
    setStatus(data.visaStatus)
  }

  useEffect(() => {
    if (data) {
      setStates()
    }
  }, [data])
  const {API_RECRUITMENT}=getEnv()

  const onFormSubmit = async (e) => {
    e.preventDefault()


    const tmpData = {
      passport: passportNumber,
      visaType: visaType,
      issueCountry: issueCountry,
      issuedOn: issuedOnDate,
      expiration: expirationDate,
      nationality: nationality,
      visaStatus: status,
    }

    //Need to added post
    setSuccess(true)
    await patchData(`${API_RECRUITMENT}/api/candidate`, tmpData).then((res) => {
      setRefetch(new Date())
      res.status === 200 ? setSuccess(true) : setSuccess(false)
    })

    setTimeout(() => {
      setSuccess(false)
      setEditMode(false)
      setDisplayButton(false)
    }, 2000)
  }

  const onFormChange = () => {
    setDisplayButton(true)
  }

  const onClearHandler = (e) => {
    e.preventDefault()
    setEditMode(false)
    setDisplayButton(false)
    setStates()
  }

  return (
    <div className="profile__right__inner">
      <div className="profile__head">
        <div className="profile__head__inner">
          <p className="profile__head__title">
            Visa Information {editMode && (<span> - Editing Mode</span>)}
          </p>
          {
            !editMode && (
              <p className="profile__head__edit" onClick={() => setEditMode(true)}>
                <span>Edit</span>
                <img src={editIc} alt="edit icon"/>
              </p>
            )
          }
        </div>
      </div>
      <form
        className={`profile__form ${editMode ? '' : 'readonly'}`}
        onSubmit={(e) => onFormSubmit(e)}
        onChange={() => onFormChange()}
      >
        <div className="profile__form__group">
          <div className="profile__form__item">
            <label className="profile__form__label">Passport №</label>
            <input
              type="text"
              className="profile__form__input"
              placeholder={`${editMode ? 'Enter passport №' : 'Not indicated'}`}
              readOnly={!editMode}
              value={passportNumber?passportNumber:''}
              onChange={(e) => setPassportNumber(e.target.value)}
            />
          </div>
          <div className="profile__form__item">
            <label className="profile__form__label">Visa Type</label>
            <ReactSelect
              options={visaOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setVisaType(item.label)
                setDisplayButton(true)
              }}
              value={visaOption.find(x => x.label === visaType) ? visaOption.find(x => x.label === visaType) : ''}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select visa type' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="visaSelect"
              instanceId="visaSelect"
            />
          </div>
        </div>
        <div className="profile__form__group">
          <div className="profile__form__item short">
            <label className="profile__form__label">Issue Country</label>
            <ReactSelect
              options={countryList}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setIssueCountry(item.label)
                setDisplayButton(true)
              }}
              value={issueCountry?countryList.find(x=>x.label===issueCountry):null}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select country' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="countrySelect"
              instanceId="countrySelect"
            />
          </div>
          <div className="profile__form__item short">
            <label className="profile__form__label">Issued On</label>
            <div className="profile__form__date">
              <DatePicker
                showYearDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={70}
                selected={issuedOnDate}
                onChange={(date: any) => {
                  setIssuedOnDate(date)
                  setDisplayButton(true)
                }}
                dateFormat="MMMM d, yyyy"
                maxDate={expirationDate ? expirationDate : new Date()}
                readOnly={!editMode}
                placeholderText="Enter date"
              />
            </div>
          </div>
          <div className="profile__form__item short">
            <label className="profile__form__label">Expiration</label>
            <div className={`profile__form__date ${!issuedOnDate&&'profile__form__date--readonly'}`}>
              <DatePicker
                showYearDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={70}
                selected={expirationDate}
                onChange={(date: any) => {
                  setExpirationDate(date)
                  setDisplayButton(true)
                }}
                dateFormat="MMMM d, yyyy"
                minDate={issuedOnDate}
                readOnly={!editMode||!issuedOnDate}
                placeholderText="Ex: 10/26"
              />
            </div>
          </div>
        </div>
        <div className="profile__form__group">
          <div className="profile__form__item">
            <label className="profile__form__label">Nationality</label>
            <ReactSelect
              options={nationalityList}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setNationality(item.label)
                setDisplayButton(true)
              }}
              value={nationality?nationalityList.find(x=>x.label===nationality):null}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select your nationality' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="nationalitySelect"
              instanceId="nationalitySelect"
            />
          </div>
          <div className="profile__form__item">
            <label className="profile__form__label">Status</label>
            <ReactSelect
              options={statusOption}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={(item: any) => {
                setStatus(item.label)
                setDisplayButton(true)
              }}
              value={statusOption.find(x => x.label === status) ? statusOption.find(x => x.label === status) : ''}
              isDisabled={!editMode}
              placeholder={`${editMode ? 'Select your status' : 'Not indicated'}`}
              styles={!editMode ? selectDisableStyle : selectCustomStyle}
              id="statusSelect"
              instanceId="statusSelect"
            />
          </div>
        </div>
        {
          displayButton && editMode ?
            <div className="profile__form__group">
              <div className="profile__form__item buttons">
                <button className="profile__form__cancel button--empty" onClick={(e) => onClearHandler(e)}>Cancel</button>
                <button className="profile__form__submit button--filled" type="submit">Save</button>
              </div>
              {success ? <p className="success-message">All changes made</p> : null}
            </div>
            : null
        }
      </form>
    </div>
  )
}

export default VisaInformationTab