import DatePicker from "react-datepicker";
import {memo, useEffect, useRef, useState} from "react";
import {components, default as ReactSelect} from "react-select";
import {selectDisableStyle} from "../../../styles/selectDisableStyle";
import {selectCustomStyle} from "../../../styles/selectCustomStyle";
import deleteData from "../../../hook/deleteData";
import {getEnv} from "@urecruits/api";
import AsyncSelect from "react-select/async";
import {selectSearchFuncPosition, selectSearchFuncPositionLimit, selectSearchFuncSkills} from "../../../hook/selectSearchFunc";
import patchData from "../../../hook/patchData";
import { Box, Chip } from "@mui/material";

const deleteIc = require("../../../image/icon/delete_ic.svg");

const Option = (props: any) => {

	return (
		<div>
			<components.Option {...props} className="profile__form__checkbox">
				<input
					id={props.value}
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label htmlFor={props.value}><span/></label>
				<p>{props.label}</p>
			</components.Option>
		</div>
	);
};

const ExperienceDetailsTabItem =
	({
		 experienceDetails,
		 item,
		 setExperienceDetails,
		 editMode,
		 validateStatus,
		 setValidateStatus,
		 displayButton,
		 addedMoreButtonRef,
		 submitButtonRef,
		 index,
		 positionOption,
		 countryList,
		 setRefetch,
		 setDisplayButton,
		 setData
	 }) => {
		const [companyNameError, setCompanyNameError] = useState(editMode ? !item?.companyName : false);
		const [positionError, setPositionError] = useState(editMode ? !item?.position : false);
		const [locationError, setLocationError] = useState(editMode ? !item?.location : false);
		const [servicePeriodsStartDateError, setServicePeriodsStartDateError] = useState(editMode ? !item?.start : false);
		const [servicePeriodsEndDateError, setServicePeriodsEndDateError] = useState(editMode ? (!item.end && !item.present) : false);
		const [skillsError, setSkillsError] = useState(editMode ? !item?.skills?.length : false);

		const fieldsValidate = () => {
			let error = 0;
			experienceDetails.map((object) => {
				!object.companyName || object.companyName.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false);
				!object.skills?.length ? setSkillsError(true) : setSkillsError(false);
				!object.position || object.position.length <= 0 ? setPositionError(true) : setPositionError(false);
				!object.location || object.location.length <= 0 ? setLocationError(true) : setLocationError(false);
				!object.start ? setServicePeriodsStartDateError(true) : setServicePeriodsStartDateError(false);
				(!object.end && !object.present) ? setServicePeriodsEndDateError(true) : setServicePeriodsEndDateError(false);

				if (!object.location || !object.position || !object.companyName || object.companyName.length <= 0 || object.position.length <= 0 || object.location.length <= 0
					|| !object.start || (!object.end && !object.present) || object.skills?.length <= 0) {
					error++;
				}
			});

			if (error === 0) {
				setValidateStatus(true);
			}
		};

		const [hasMore, setHasMore] = useState(false);
		const [offset, setOffset] = useState(30);
		const [searchValue, setSearchValue] = useState(null);
		const [options, setOptions] = useState([]);

		const loadOptions = async (inputValue) => {					
			setSearchValue(inputValue)
			if (hasMore) {
			  try {
				const response = await selectSearchFuncPositionLimit(`find=${inputValue && inputValue}&offset=${offset}`);
				if (response.length > 0) {
				  const newOptions = response.map((item) => ({ value: item.id, label: item.label }));
				  setOffset(offset + 30);
				  const data = [...options, ...newOptions];
				  setOptions(data)					
				  setHasMore(false);
				  return data
				}else{
					setHasMore(false);
					return options
				}
			  } catch (error) {
				console.error('Error loading more options:', error);
			  }
			} else {
			  const data = await selectSearchFuncPositionLimit(`find=${inputValue && inputValue}`);
			  setOptions(data);
			  return (data);
			}
		  };
		
		  useEffect(() => {
			if (hasMore || options?.length===0) {				
			  loadOptions(searchValue!==null ? searchValue : "");
			}
		  }, [hasMore,options?.length===0]);
		
		useEffect(() => {
			if (!validateStatus && editMode) {
				//added more click validate
				addedMoreButtonRef.current.onclick = function () {
					fieldsValidate();
				};

				//on submit click validate
				if (displayButton) {
					submitButtonRef.current.onclick = function () {
						fieldsValidate();
					};
				}
			}
		}, [validateStatus, experienceDetails, item, editMode]);

		const {API_RECRUITMENT} = getEnv();

		const onRemoveItemHandler = async (e) => {
			e.preventDefault;
			if (editMode) {
				if (item?.isResumeParsed) {
					setData(oldData => {
						let experiences = [];
						experiences = experienceDetails?.filter(i => i.id !== item.id)
						oldData.experiences = experiences;
						return { ...oldData }
					});
				} else {

					const res = await deleteData(`${API_RECRUITMENT}/api/candidate/experience`, item);
					if (res.status == 200) {
						setData(oldData => {
							const _oldData = JSON.parse(JSON.stringify(oldData));
							let experiences = experienceDetails;
							experiences = experiences.filter(i => i.id !== item.id);
							return { ..._oldData, experiences }
						})
					}
				}
			}
		};

		//TODO: need to add validate for checkbox
		return (
			<div className="profile__form__part">
				<div className={`flex`}>
					<Box>
						{item?.isResumeParsed && <Chip variant='outlined' color='error' label="Unsaved" />}
					</Box>
					<div className={`profile__form__delete ${!editMode ? "hide" : ""}`}>
						<div className="profile__form__delete--inner" onClick={e => onRemoveItemHandler(e)}>
							<span className="profile__form__delete--text">Delete</span>
							<img src={deleteIc} alt="delete icon" className="profile__form__delete--icon" />
						</div>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${companyNameError ? "error" : ""}`}>
						<label className="profile__form__label">Company Name<span> *</span></label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter company name" : "Not indicated"}`}
							readOnly={!editMode}
							value={item.companyName}
							onChange={(e) => {
								setExperienceDetails([...experienceDetails].map(object => {
									if (object.id === item.id) {
										return {
											...object,
											companyName: e.target.value,
										};
									} else return object;
								}));
								e.target.value.length <= 0 ? setCompanyNameError(true) : setCompanyNameError(false);
							}}
						/>
						<p className="error-message">This is required field</p>
					</div>
					<div className={`profile__form__item ${positionError ? "error" : ""}`}>
						<label className="profile__form__label">Position<span> *</span></label>
						<AsyncSelect
							cacheOptions
							loadOptions={loadOptions}
							defaultOptions={options && options}
							options={options && options}
							isLoading={hasMore}
							isDisabled={!editMode}
							onBlur={()=>{loadOptions("")}} 
							value={item?.position ? { label: item.position } : searchValue!== null ? searchValue : null}
							onChange={(option: any) => {								
								setDisplayButton(true);
								setExperienceDetails([...experienceDetails].map(object => {
									if (object.id === item.id) {
										return {
											...object,
											position: option.label,
										}
									}else return object
								}));
								option?.label ? setPositionError(false) : setPositionError(true);
							}}
							placeholder={`${editMode ? "Select your position" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="positionSelect"
							instanceId="positionSelect"
							onMenuScrollToBottom={(e)=>{								
								!hasMore && setHasMore(true)
							}}
        />
						<p className="error-message">This is required field</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${locationError ? "error" : ""}`}>
						<label className="profile__form__label">Location<span> *</span></label>
						<ReactSelect
							options={countryList}
							closeMenuOnSelect={true}
							hideSelectedOptions={false}
							onChange={(currentValue: any) => {
								setDisplayButton(true);
								setExperienceDetails([...experienceDetails].map(object => {
									if (object.id === item.id) {
										return {
											...object,
											location: currentValue.label,
										};
									} else return object;
								}));
								currentValue.length <= 0 ? setLocationError(true) : setLocationError(false);
							}}
							value={item.location ? countryList.find(x => x.label === item.location) : null}
							isDisabled={!editMode}
							placeholder={`${editMode ? "Select location" : "Not indicated"}`}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
						/>
						<p className="error-message">This is required field</p>
					</div>
					<div className={`profile__form__item`}>
						<label className="profile__form__label">Job Description</label>
						<input
							type="text"
							className="profile__form__input"
							placeholder={`${editMode ? "Enter job description" : "Not indicated"}`}
							readOnly={!editMode}
							value={item.jobDescription}
							onChange={(e) => {
								setExperienceDetails([...experienceDetails].map(object => {
									if (object.id === item.id) {
										return {
											...object,
											jobDescription: e.target.value,
										};
									} else return object;
								}));
							}}
						/>
						<p className="error-message">This is required field</p>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item short after-line ${servicePeriodsStartDateError ? "error" : ""}`}>
						<label className="profile__form__label">Service Periods<span> *</span></label>
						<div className="profile__form__date">
							<DatePicker
								showYearDropdown={true}
								scrollableYearDropdown={true}
								yearDropdownItemNumber={70}
								selected={item.start ? new Date(item.start) : null}
								onChange={(date: any) => {
									setDisplayButton(true);
									setExperienceDetails([...experienceDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												start: date,
											};
										} else return object;
									}));
									!date ? setServicePeriodsStartDateError(true) : setServicePeriodsStartDateError(false);
								}}
								dateFormat="MMMM d, yyyy"
								maxDate={new Date()}
								readOnly={!editMode}
								placeholderText="Start of service period"
							/>
						</div>
						<p className="error-message">This is required field</p>
					</div>
					<div className={`profile__form__item short ${servicePeriodsEndDateError ? "error" : ""}`}>
						<div className={`profile__form__date ${!item.start || item.present ? "profile__form__date--readonly" : ""}`}>
							<DatePicker
								showYearDropdown={true}
								scrollableYearDropdown={true}
								yearDropdownItemNumber={70}
								selected={item.end ? new Date(item.end) : null}
								disabled={item.present}
								onChange={(date: any) => {
									setDisplayButton(true);
									setExperienceDetails([...experienceDetails].map(object => {
										!date && !object.present ? setServicePeriodsEndDateError(true) : setServicePeriodsEndDateError(false);

										if (object.id === item.id) {
											return {
												...object,
												end: date,
											};
										} else return object;
									}));
								}}
								dateFormat="MMMM d, yyyy"
								minDate={item.start ? new Date(item.start) : null}
								readOnly={!editMode || !item.start}
								placeholderText="End of service period"
							/>
						</div>
						<p className="error-message">This is required field</p>
					</div>
					<div className="profile__form__item present short">
						<label className="profile__form__label"></label>
						<div className="custom-checkbox">
							<input
								type="checkbox"
								className="custom-checkbox__input"
								id={`checkbox${item.id}`}
								name={`checkbox${item.id}`}
								checked={item.present}
								onChange={(e) => {
									setExperienceDetails([...experienceDetails].map(object => {
										if (object.id === item.id) {
											return {
												...object,
												present: !item.present,
											};
										} else return object;
									}));
								}}
							/>
							<label htmlFor={`checkbox${item.id}`} className="custom-checkbox__label">
								<div className="custom-checkbox__item">
									<span></span>
								</div>
								<p className="custom-checkbox__text">Still working</p>
							</label>
						</div>
					</div>
				</div>
				<div className="profile__form__group">
					<div className={`profile__form__item ${skillsError ? "error" : ""}`}>
						<label className="profile__form__label">Skills<span> *</span></label>
						<AsyncSelect
							cacheOptions
							loadOptions={(inputValue) => inputValue.length > 0 ? selectSearchFuncSkills(inputValue) : selectSearchFuncSkills("")}
							isMulti
							defaultOptions
							value={item.skills}
							isDisabled={!editMode}
							onChange={(option: any) => {
								setExperienceDetails([...experienceDetails].map(object => {
									if (object.id === item.id) {
										return {
											...object,
											skills: option,
										};
									} else return object;
								}));
								item.skills?.length < 0 ? setSkillsError(true) : setSkillsError(false);
							}}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							components={{
								Option,
							}}
							styles={!editMode ? selectDisableStyle : selectCustomStyle}
						/>
						<p className="error-message">This is required field</p>
					</div>
				</div>
			</div>
		);
	};

export default memo(ExperienceDetailsTabItem);