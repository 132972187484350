import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePermissions } from "../../../../store/rolesReducer";
import { postData } from "../../../../hook/postData";
import { getEnv } from "@urecruits/api";
import deleteData from "../../../../hook/deleteData";

const { API_RECRUITMENT } = getEnv();

export const usePermissionsTableItem = (item, role) => {
  const [displayInfo, setDisplayInfo] = useState(false);
  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });

  const dispatch = useDispatch();
  const dbPermissions = useSelector(
    (state: any) => state.permissions.dbPermissions
  );

  const handleOnPermissionChange = async (isChecked, permission) => {
    dispatch(
      updatePermissions({
        name: item.name,
        permissionKey: permission,
        value: isChecked,
        role: role,
      })
    );
    const updatedData = dbPermissions.map((rolePermission) => {
      if (rolePermission.auth0_id === role) {
        return {
          ...rolePermission,
          permissions: rolePermission.permissions.map((i) => {
            if (i.name === item.name) {
              return {
                ...i,
                [permission]: isChecked,
              };
            } else {
              return i;
            }
          }),
        };
      } else {
        return rolePermission;
      }
    });
    const currentRolePermission = updatedData.find((i) => i.auth0_id === role);
    const payload = {
      dbPermissions: currentRolePermission.permissions,
      permissions: [`${item.key}:${permission}`],
      roleId: role,
    };
    if (currentRolePermission) {
      if (isChecked) {
        await postData(
          `${API_RECRUITMENT}/api/company/role/add-permissions`,
          payload
        );
        setToastNotification({ state: true, message: "Permission added" });
      } else {
        if (currentRolePermission) {
          await deleteData(
            `${API_RECRUITMENT}/api/company/role/remove-permissions`,
            payload
          );
          setToastNotification({ state: true, message: "Permission removed" });
        }
      }
    } else {
        setToastNotification({ state: true, message: "Permission Not found" });
    }
  };

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };
  return {
    handleToastNotificationClose,
    toastNotification,
    displayInfo,
    setDisplayInfo,
    handleOnPermissionChange,
  };
};
